import React from "react";
import {Container, ElasticsearchDataTable, TableColumn, TableRow} from "@component";
import {Code, SearchHit, SearchResponse} from "@type";
import {useUserActivityTendencyContext} from "@page";
import {TendencyType} from "@enum";
import styled from "styled-components";
import moment from "moment/moment";

interface Props {
  isLoading: boolean
  data?: SearchResponse
  regions?: any
  tags?: any
  types?: Array<Code>
}

const UserTendencyPresenter = ({ isLoading, data, regions, tags, types }: Props) => {
  const { setDetailAttribute, modalOpen } = useUserActivityTendencyContext()

  const optionalHyphen = (value: any) => {
    return value || '-'
  }

  const ColumnWithDetail = ({ ga, snsId, type, value, score, url }: {
    ga: string,
    snsId?: string,
    type: TendencyType,
    value: string,
    score: number
    url?: string
  }) => {
    const handleClickIcon = () => {
      setDetailAttribute(() => ({
        ga: ga,
        snsId: snsId,
        type: type
      }))
      modalOpen(type)
    }

    const Style = {
      InnerColumn: styled.div`
        display: flex;
        align-items: center;
      `,
      Value: styled.span`
        line-height: 20px;
      `,
      Score: styled.span<{
        color?: string
      }>`
        display: flex;
        align-items: center;
        background-color: ${({ color }) => color ? color : 'red'};
        color: white;
        font-weight: bold;
        padding: 2px 3px;
        margin-left: 3px;
        font-size: 12px;
        border-radius: 5px;
        padding-top: 4px;
      `
    }

    return (
      <TableColumn align={"center"} url={url} description={{ enable: value !== undefined && value !== '-', onClick: handleClickIcon }}>
        <Style.InnerColumn>
          <Style.Value>{ optionalHyphen(value) }</Style.Value>
          {
            score && (
              <Style.Score color={score < 0 ? '#006fff' : 'red'}>{ score }</Style.Score>
            )
          }
        </Style.InnerColumn>
      </TableColumn>
    )
  }

  const getAreaCodeAsString = (code: { regionCode: number, sigunguCode: number }) => {
    const { regionCode, sigunguCode } = code
    let area = regions && regionCode ? regions[regionCode].name : '-'
    if (regions[regionCode][sigunguCode]) {
      area += ` ${regions[regionCode][sigunguCode].name}`
    }
    return area
  }

  return (
    <Container classNames={["actionTrackDashboard"]}>
      <ElasticsearchDataTable
        isLoading={isLoading}
        title={"사용자 성향 테이블"}
        headers={["GA", "SNS_ID", "갱신일시", "키워드", "지역 성향", "방문 지역 성향", "선호 카테고리", "비선호 카테고리", "선호 콘텐츠", "비선호 콘텐츠", "선호 태그", "비선호 태그", "AI 플래너 코스 생성 지역", "성별", "연령"]}>
        {
          data ? data.hits.hits.map((hit: SearchHit, index) => {
            const { ga, snsId, gender, birthYear } = hit.sourceAsMap
            const { keyword, visit, area, category, content, tag, courseArea } = hit.sourceAsMap.tendency
            return (
              <TableRow
                key={index}
                enableDetailButton={true}
                enableOverlay={false}>
                <TableColumn align={"center"}>
                  { optionalHyphen(ga) }
                </TableColumn>
                <TableColumn align={"center"}>
                  { optionalHyphen(snsId) }
                </TableColumn>
                <TableColumn>{ moment(hit.sourceAsMap['@timestamp']).format("YY/MM/DD HH:mm:ss") }</TableColumn>
                <ColumnWithDetail ga={ga} snsId={snsId} type={TendencyType.KEYWORD}
                                  score={keyword?.score}
                                  value={keyword?.value} />
                <ColumnWithDetail ga={ga} snsId={snsId} type={TendencyType.AREA}
                                  score={area?.score}
                                  value={area && regions && getAreaCodeAsString(area?.value)} />
                <ColumnWithDetail ga={ga} snsId={snsId} type={TendencyType.VISIT}
                                  score={visit?.score}
                                  value={visit && regions && getAreaCodeAsString(visit?.value)} />
                <ColumnWithDetail ga={ga} snsId={snsId} type={TendencyType.CATEGORY}
                                  score={category?.positive?.score}
                                  value={category && category?.positive && types && getContentType(category?.positive?.value, types)} />
                <ColumnWithDetail ga={ga} snsId={snsId} type={TendencyType.CATEGORY}
                                  score={category?.negative?.score}
                                  value={category && category?.negative && types && getContentType(category?.negative?.value, types)} />
                <ColumnWithDetail ga={ga} snsId={snsId} type={TendencyType.CONTENT}
                                  url={content?.positive ? `https://korean.visitkorea.or.kr/detail/detail_view.do?cotid=${content?.positive?.value}` : undefined}
                                  score={content?.positive?.score}
                                  value={content?.positive && content.positive?.value} />
                <ColumnWithDetail ga={ga} snsId={snsId} type={TendencyType.CONTENT}
                                  url={content?.negative ? `https://korean.visitkorea.or.kr/detail/detail_view.do?cotid=${content?.negative?.value}` : undefined}
                                  score={content?.negative?.score}
                                  value={content?.negative && content?.negative?.value} />
                <ColumnWithDetail ga={ga} snsId={snsId} type={TendencyType.TAG}
                                  score={tag?.positive?.score}
                                  value={tag?.positive && tag?.positive?.value} />
                <ColumnWithDetail ga={ga} snsId={snsId} type={TendencyType.TAG}
                                  score={tag?.negative?.score}
                                  value={tag?.negative && tag?.negative?.value} />
                <ColumnWithDetail ga={ga} snsId={snsId} type={TendencyType.COURSE_AREA}
                                  score={courseArea?.score}
                                  value={courseArea && courseArea && getAreaCodeAsString(courseArea?.value)} />
                <TableColumn align={"center"}>{ gender && gender === '1' ? '남' : gender === '2' ? '여' : '-' }</TableColumn>
                <TableColumn align={"center"}>{ birthYear ? `${new Date().getFullYear() - birthYear}세` : '-' }</TableColumn>
              </TableRow>
            )
          }) : <></>
        }
      </ElasticsearchDataTable>
    </Container>
  )
}

const getContentType = (contentType: number, codeList?: Array<Code>) => {
  const type = getContentTypeAsString(contentType)
  if (type) {
    return type
  }
  return getContentTypeFromCodeList(contentType, codeList)
}

const getContentTypeFromCodeList = (contentType: number, codeList?: Array<Code>) => {
  const array = codeList?.filter(code => code.big === `${contentType}`)
    .map(code => code.value)
  if (array && array.length > 0) {
    return array[0]
  }
  return `${contentType}`
}

const getContentTypeAsString = (contentType: number) => {
  switch (contentType) {
    case 12:
      return "관광지"
    case 14:
      return "문화시설"
    case 15:
      return "축제행사공연"
    case 25:
      return "여행코스"
    case 28:
      return "레포츠"
    case 32:
      return "숙박"
    case 38:
      return "쇼핑"
    case 39:
      return "음식점"
    default:
      return undefined
  }
}

export default UserTendencyPresenter
