import React from "react";
import { Container, PrimaryDataTable, TableColumn, TableRow } from "@component";
import { Bucket, NumberBucket, SearchResponse } from "@type";
import { useTableContext } from "@hook";

interface Props {
  data?: SearchResponse
  dataCount?: SearchResponse
  cacheContents?: { [key: string]: string }
  isLoading: boolean
}

const ContentStatisticsPresenter = ({ data, dataCount, isLoading }: Props) => {
  const {
    keywordState: [ keyword ],
    searchTypesState: [ searchTypes ]
  } = useTableContext()
  return (
    <Container>
      <PrimaryDataTable
        title={"콘텐츠 성향"}
        pagination={false}
        headers={["COT_ID", "누적횟수", "비율"]}
        useKeyword={true}
        dateType={'date'}
        selectItems={[
          {
            label: '선호/비선호',
            key: 'condition',
            items: [
              {
                label: '선호 콘텐츠',
                value: 'POSITIVE'
              },
              {
                label: '비선호 콘텐츠',
                value: 'NEGATIVE'
              }
            ]
          },
          {
            label: '검색구분',
            key: 'type',
            items: [
              {
                label: '콘텐츠명',
                value: 'TITLE'
              },
              {
                label: 'COT_ID',
                value: 'COT_ID'
              }
            ]
          }
        ]}
        isLoading={isLoading}>
        {
          data ? data.aggregations.asMap[0].buckets
            .map((bucket: Bucket, index) => {
            const { key, aggregations } = bucket;
            const { value } = aggregations.asMap[1] as NumberBucket
            const totalCount = dataCount?.aggregations.asMap[0].value
            const percentage = totalCount ? value / totalCount * 100 : 0
            return (
              <TableRow
                key={index}
                enableDetailButton={true}
                enableOverlay={false}
                onClickDetailButton={() => {}}>
                <TableColumn align={"center"} url={`https://korean.visitkorea.or.kr/detail/detail_view.do?cotid=${key}`}>{ key }</TableColumn>
                <TableColumn align={"center"}>{ value }건</TableColumn>
                <TableColumn align={"center"} percentage={percentage}>{ percentage.toFixed(1) }%</TableColumn>
              </TableRow>
            )
          }) : <></>
        }
      </PrimaryDataTable>
    </Container>
  )
}

export default ContentStatisticsPresenter
