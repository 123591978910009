import React, { ReactNode } from "react";
import { CustomSelect, CustomTextBox, Table, TableContainerStyle, TableTitleStyle } from "@component";
import TableSearchBox from "@component/Table/TableSearchBox";
import { useTableContext } from "@hook";

interface Props {
  title?: string
  headers: Array<string>
  isLoading: boolean
  pagination?: boolean
  children: ReactNode
  dateType?: 'date' | 'datetime-local'
  totalCount?: number
  lastPage?: number
  useFilter?: boolean
  useKeyword?: boolean
  selectItems?: Array<{
    label: string
    key: string
    hidden?: boolean
    items: Array<{ value: any, label: string }>
  }>
}

const PrimaryDataTable = ({ title, headers, isLoading, children, dateType, selectItems, pagination = true, totalCount, lastPage, useKeyword = false, useFilter = true }: Props) => {
  const {
    searchTypesState: [ , setSearchTypes ],
    keywordState: [ , setKeyword ]
  } = useTableContext()

  const handleChangeSelect = (key: string) => (value: string) => {
    setSearchTypes(prevState => ({
      ...prevState,
      [key]: value
    }))
  }

  const handleChangeKeyword = (keyword: string) => {
    setKeyword(keyword)
  }

  return (
    <TableContainerStyle>
      {
        title && <TableTitleStyle>{ title }</TableTitleStyle>
      }
      {
        useFilter && (
          <TableSearchBox dateType={dateType}>
            {
              selectItems && selectItems.filter(({ hidden }) => !hidden).map((selectItem) => {
                const { label, key, items } = selectItem
                return (
                  <CustomSelect width={"150px"} items={items} label={label} onChange={handleChangeSelect(key)} />
                )
              })
            }
            {
              useKeyword && <CustomTextBox width={"300px"} onChange={handleChangeKeyword} />
            }
          </TableSearchBox>
        )
      }
      <Table
        xScroll={true}
        yScroll={true}
        isLoading={isLoading}
        pagination={pagination}
        totalCount={totalCount}
        lastPage={lastPage}
        headers={headers}>
        { children }
      </Table>
    </TableContainerStyle>
  )
}

export default PrimaryDataTable
