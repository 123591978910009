import React, { useRef } from "react";
import { useClickOutsideOfRef, useModalContext } from "@hook";
import { Button } from "@component";
import { ColorType } from "@enum";

interface Props {
  modalKey: string
  title?: string
  width?: string
  children?: JSX.Element | Array<JSX.Element>
  params?: any
  onClose?: () => void
  footer?: boolean
  onConfirm?: (params: any | undefined) => void
}

const Modal = ({ modalKey, title = '알림', width, children, onClose, onConfirm, footer = true, params }: Props) => {
  const context = useModalContext()
  const containerRef = useRef<HTMLDivElement>(null)
  const { isOpen, open, close } = context[modalKey]

  const handleClickConfirm = () => {
    onConfirm && onConfirm(params)
    handleClickClose()
  }

  const handleClickClose = () => {
    onClose && onClose()
    close && close()
  }

  useClickOutsideOfRef({
    ref: containerRef,
    onClick: handleClickClose
  })

  return (
    <div className={["modalWrapper", isOpen ? "show" : "hide"].join(" ")}>
      <div className={"modalUnderlay"} />
      <div ref={containerRef} className={"modalContent"} style={{ width }}>
        <div className={"header"}>
          <h4 className={"title"}>{ title }</h4>
          <Button color={ColorType.WHITE} icon={"close"} onClick={handleClickClose} />
        </div>
        <section className={"section"}>
          { children }
        </section>
        {
          footer && <div className={"footer"}>
            <div className={"buttons"}>
              <Button color={ColorType.PRIMARY} text={"확인"} onClick={handleClickConfirm}/>
              <Button color={ColorType.WHITE} text={"취소"} onClick={handleClickClose}/>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default Modal
