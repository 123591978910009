import React from "react";
import { UseQueryResult } from "react-query/types/react/types";
import { Code, SearchResponse } from "@type";
import * as QueryBuilder from "@util";
import { useTableContext } from "@hook";
import useFetchElasticsearchQueryProxy from "../../hook/query/elasticsearch/useFetchElasticsearchQueryProxy";
import { CategoryStatisticsPresenter } from "@presenter";
import { useTypeCodeQuery } from "@hook/query";

const CategoryStatisticsContainer = () => {
  const DEFAULT_PAGING_SIZE = 0
  const {
    keywordState: [ keyword ],
    fromDateTimeState: [ fromDateTime ],
    toDateTimeState: [ toDateTime ],
    searchTypesState: [ searchTypes ],
  } = useTableContext()

  const types: UseQueryResult<Array<Code>> = useTypeCodeQuery(3)

  const createQuery = (aggs: any) => {
    return QueryBuilder.createQuery({
      size: DEFAULT_PAGING_SIZE,
      gte: fromDateTime,
      lte: toDateTime,
      timeFieldName: 'date',
      aggs: aggs,
      filter: [
        {
          "match_phrase": {
            "type": "CATEGORY"
          }
        },
        {
          "match_phrase": {
            "key.condition": searchTypes.condition
          }
        }
      ]
    })
  }

  const { data: dataCount } : UseQueryResult<SearchResponse> = useFetchElasticsearchQueryProxy("user-tendency-statistics", createQuery({
    0: {
      "sum": {
        "field": "count"
      }
    }
  }), false)

  const { data, isLoading }: UseQueryResult<SearchResponse> = useFetchElasticsearchQueryProxy("user-tendency-statistics", createQuery({
    0: {
      "terms": {
        "field": "key.value.keyword",
        "order": {
          "1": "desc"
        },
        "size": 20
      },
      aggs: {
        "1": {
          "sum": {
            "field": "count"
          }
        }
      }
    }
  }), false)

  return (
    <CategoryStatisticsPresenter
      isLoading={isLoading}
      data={data}
      dataCount={dataCount}
      types={types.data}
    />
  )
}

export default CategoryStatisticsContainer
