import React, { ChangeEvent, useRef } from "react";
import moment, { Moment } from "moment";

interface Props {
  defaultValue?: Moment
  onChange?: (value: Moment) => void
  type?: 'date' | 'datetime-local'
}

const InputDateTime = ({ defaultValue, onChange, type }: Props) => {
  const input = useRef<HTMLInputElement>(null)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(moment(e.target.value))
    }
  }

  return (
    <div className={"inputDateTimeWrapper"}>
      <input ref={input}
             className={"inputDateTime"}
             type={type}
             defaultValue={defaultValue?.format(type === 'date' ? 'yyyy-MM-DD' : "yyyy-MM-DDTHH:mm")}
             onChange={handleChange} />
    </div>
  )
}

export default InputDateTime
