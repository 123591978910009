import { ActivityDetailModalProps } from "@presenter";
import { Modal, PrimaryDataTable, TableColumn, TableRow } from "@component";
import React from "react";

const UserTendencyVisitModal = ({ data, isLoading, regions }: ActivityDetailModalProps) => {
  const getAreaCodeAsString = (code: { regionCode: number, sigunguCode: number }) => {
    const { regionCode, sigunguCode } = code
    let area = regions && regionCode ? regions[regionCode].name : '-'
    if (regions[regionCode][sigunguCode]) {
      area += ` ${regions[regionCode][sigunguCode].name}`
    }
    return area
  }

  return (
    <Modal width={"80%"} modalKey={"modalVisit"} title={"사용자 방문 지역 성향 상세"} footer={false}>
      <PrimaryDataTable headers={["지역", "점수", "최고 점수 대비 백분율"]} isLoading={isLoading} useFilter={false} pagination={false}>
        {
          data ? data.visitList?.map((item: any, index: number) => {
            const { key: { regionCode, sigunguCode }, score } = item;
            const percentage = score / data.visitList[0].score * 100
            return (
              <TableRow
                key={index}
                enableDetailButton={true}
                enableOverlay={false}
                onClickDetailButton={() => {}}>
                <TableColumn align={"center"}>{ getAreaCodeAsString({ regionCode, sigunguCode }) }</TableColumn>
                <TableColumn align={"center"}>{ score }점</TableColumn>
                <TableColumn align={"center"} percentage={percentage}>{ percentage.toFixed(1) }%</TableColumn>
              </TableRow>
            )
          }) : <></>
        }
      </PrimaryDataTable>
    </Modal>
  )
}

export default UserTendencyVisitModal
