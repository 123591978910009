import React from "react";
import { TableContext, useTable } from "@hook";
import { PageTemplate } from "@component";
import { VisitAreaStatisticsContainer } from "@container";

const VisitAreaStatistics = () => {
  const tableState = useTable({
    initial: {
      searchTypes: {
        type: 'SIDO'
      }
    }
  })
  return (
    <TableContext.Provider value={tableState}>
      <PageTemplate>
        <VisitAreaStatisticsContainer />
      </PageTemplate>
    </TableContext.Provider>
  )
}

export default VisitAreaStatistics
