import { Modal, PrimaryDataTable, TableColumn, TableRow } from "@component";
import { ActivityDetailModalProps } from "@presenter";
import React from "react";

const UserTendencyContentModal = ({ data, isLoading }: ActivityDetailModalProps) => {
  return (
    <Modal width={"80%"} modalKey={"modalContent"} title={"사용자 콘텐츠 성향 상세"} footer={false}>
      <PrimaryDataTable headers={["콘텐츠 ID", "점수", "최고 점수 대비 백분율"]} isLoading={isLoading} useFilter={false} pagination={false}>
        {
          data ? data.contentList?.map((item: any, index: number) => {
            const { key, score } = item;
            const percentage = score / data.contentList[0].score * 100
            return (
              <TableRow
                key={index}
                enableDetailButton={true}
                enableOverlay={false}
                onClickDetailButton={() => {}}>
                <TableColumn align={"center"}
                             url={`https://korean.visitkorea.or.kr/detail/detail_view.do?cotid=${key}`}>{ key }</TableColumn>
                <TableColumn align={"center"}>{ score }점</TableColumn>
                <TableColumn align={"center"} percentage={percentage}>{ percentage.toFixed(1) }%</TableColumn>
              </TableRow>
            )
          }) : <></>
        }
      </PrimaryDataTable>
    </Modal>
  )
}

export default UserTendencyContentModal
