import React from "react";
import styled from "styled-components";

const Styles = {
  Term: styled.div`
    padding: 10px;
    border: 1px solid rgb(216 216 216);
    margin: 5px;
    box-shadow: 0 0 15px 0 #dddddd;
    display: flex;
    align-items: center;
    border-radius: 4px;
  `,
  KeyLabel: styled.span`
    font-size: 16px;
    font-weight: bold;
    padding-top: 4px;
  `,
  ValueLabel: styled.span`
    font-size: 16px;
    padding-top: 4px;
  `,
  CloseIcon: styled.i`
    font-size: 16px;
    font-weight: bold;
    margin-left: 10px;
    padding-top: 2px;
    
    &:hover {
      color: grey;
      cursor: pointer;
    }
  `
}

export interface TermItem {
  id: string
  key: string
  value: any
  keyLabel?: string
  valueLabel?: string
}

export interface Props {
  item: TermItem
  onRemove: (item: TermItem) => void
}

const Term = ({ item: { id, key, value, keyLabel, valueLabel }, onRemove }: Props) => {
  const handleRemove = () => {
    onRemove && onRemove({ id, key, value, keyLabel, valueLabel })
  }

  return (
    <Styles.Term>
      <Styles.KeyLabel>{ keyLabel ? keyLabel : key }&nbsp;:&nbsp;</Styles.KeyLabel>
      <Styles.ValueLabel>{ valueLabel ? valueLabel : value }</Styles.ValueLabel>
      <Styles.CloseIcon className={"material-icons"} onClick={handleRemove}>close</Styles.CloseIcon>
    </Styles.Term>
  )
}

export default Term
