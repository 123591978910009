import React from "react";
import { Container, TableColumn, TableRow, TermItem } from "@component";
import { getActionType } from "@enum";
import moment from "moment";
import { getByNumber, SearchHit, SearchResponse, SimpleTagInfo } from "@type";
import { useTableContext } from "@hook";
import ElasticsearchDataTable from "../../component/Table/ElasticsearchDataTable";
import { generateRandomString } from "@util";
import { getFieldByActionType } from "@enum/ActionType";

interface Props {
  data?: SearchResponse
  tags?: Array<SimpleTagInfo>
  users?: Array<any>
  regions?: any
  isLoading: boolean
}

const ActionTrackPresenter = ({ data, tags, users, regions, isLoading }: Props) => {
  const {
    pageState: [ page, setPage ],
    termsState: [ terms, , , setTerms ]
  } = useTableContext()

  const convertDateString = (date: string) => {
    return moment(date).add(9, 'h').format("YY/MM/DD HH:mm:ss")
  }

  const optionalHyphen = (value: any) => {
    return value || '-'
  }

  const handleColumnSearch = (key: string, value: any, keyLabel?: string, valueLabel?: string) => {
    const termsList = terms ? terms : [] as Array<TermItem>
    const id = generateRandomString()
    setTerms([...termsList, { id, key, value, keyLabel, valueLabel }])
    setPage(0)
  }

  const optionalUser = (snsId: string) => {
    if (users) {
      const filter = users?.filter(user => user.id === snsId)
      if (filter) {
        return filter[0]
      }
    }
  }

  return (
    <Container>
      <ElasticsearchDataTable
        title={"사용자 활동 히스토리"}
        headers={["#", "일시", "GA", "SNS", "활동구분", "활동 페이지", "활동 콘텐츠", "활동 세부"]}
        isLoading={isLoading}>
        {
          data ? data.hits.hits.map((hit: SearchHit, index) => {
            const item = hit.sourceAsMap
            const timestamp = item['@timestamp']
            const { action_info } = item
            const { ga, tagid, type, snsId, areacode, sigungucode } = action_info
            const actionType = getActionType(type)
            const user = optionalUser(action_info.snsId)
            const value = getFieldByActionType({ actionType, actionInfo: action_info, tags, regions })
            return (
              <TableRow
                key={index}
                enableDetailButton={true}
                enableOverlay={false}
                onClickDetailButton={() => {}}>
                <TableColumn align={"center"}>{ page * 500 + index + 1 }</TableColumn>
                <TableColumn align={"center"}>{ convertDateString(timestamp) }</TableColumn>
                <TableColumn
                  align={"center"}
                  searchEnabled={!!ga}
                  onSearch={() => handleColumnSearch("action_info.ga", optionalHyphen(ga), "ga")}>
                  { optionalHyphen(ga) }
                </TableColumn>
                <TableColumn
                  align={"center"}>
                  { user ? `${user.username} / ${user.identify} / ${getByNumber(user.type)}` : snsId ? snsId : '-' }
                </TableColumn>
                <TableColumn
                  align={"center"}
                  searchEnabled
                  onSearch={() => handleColumnSearch("action_info.type", type, "활동구분", actionType)}>
                  { actionType }
                </TableColumn>
                <TableColumn>
                  { action_info?.fromuri }
                </TableColumn>
                <TableColumn
                  align={"center"}
                  searchEnabled={!!action_info.cotid}
                  url={action_info.cotid ? `https://korean.visitkorea.or.kr/detail/detail_view.do?cotid=${action_info.cotid}` : undefined}
                  onSearch={() => handleColumnSearch("action_info.cotid", action_info.cotid, "cotId")}>
                  { action_info.cotid ? action_info.cotid : '-' }
                </TableColumn>
                <TableColumn
                  // searchEnabled={value && value !== '-'}
                  onSearch={() => handleColumnSearch("action_info.cotid", action_info.cotid, "cotId")}
                  align={"center"}>
                  { value }
                </TableColumn>
              </TableRow>
            )
          }) : <></>
        }
      </ElasticsearchDataTable>
    </Container>
  )
}

export default ActionTrackPresenter
