import React, { useEffect, useState } from "react";
import { Container, PrimaryDataTable, TableColumn, TableRow } from "@component";
import { Aggregations, Bucket, Code, SearchResponse } from "@type";

interface Props {
  data?: SearchResponse
  dataCount?: SearchResponse
  types?: Array<Code>
  isLoading: boolean
}

const AppMainUserStatisticsPresenter = ({ data, dataCount, types, isLoading }: Props) => {
  const [ map, setMap ] = useState<any>([])
  useEffect(() => {
    if (data) {
      setMap(() => getAggregationsBuckets(data.aggregations).reduce((accumulator, bucket) => {
        const { key } = bucket
        const { value } = getAggregationsKey(bucket.aggregations, 'userCount')
        return {
          ...accumulator,
          [key]: value,
        }
      }, {}))
    }
  }, [ data ])

  const getAggregationsBuckets = (aggregations: any) => {
    const aggregationsAsMap = aggregations.asMap as Aggregations
    return aggregationsAsMap[0].buckets.sort(sortAscending)
  }

  const getAggregationsKey = (aggregations: any, key: string) => {
    const aggregationsAsMap = aggregations.asMap as Aggregations
    return aggregationsAsMap[key]
  }

  const sortAscending = (a: Bucket, b: Bucket) => a.keyAsString > b.keyAsString ? 1 : -1

  const existsSnsIdRating = map.filteredExistsSnsId / (map.filteredExistsSnsId + map.filteredNotExistsSnsId) * 100
  const notExistsSnsIdRating = map.filteredNotExistsSnsId / (map.filteredExistsSnsId + map.filteredNotExistsSnsId) * 100
  const existsBirthYearAndGenderRating = map.filteredExistsBirthYearAndGender / (map.filteredExistsBirthYearAndGender + map.filteredNotExistsBirthYearAndGender) * 100
  const notExistsBirthYearAndGenderRating = map.filteredNotExistsBirthYearAndGender / (map.filteredExistsBirthYearAndGender + map.filteredNotExistsBirthYearAndGender) * 100
  return (
    <Container>
      <PrimaryDataTable
        title={"APP 메인 통계 > 접속자 통계"}
        pagination={false}
        headers={["통계 항목명", "구분", "사용자 수", "전체 대비 비율"]}
        useKeyword={false}
        dateType={'datetime-local'}
        isLoading={isLoading}>
        <TableRow
          key={Math.random()}
          enableDetailButton={true}
          enableOverlay={false}
          onClickDetailButton={() => {}}>
          <TableColumn align={"center"} rowSpan={2}>로그인 여부</TableColumn>
          <TableColumn align={"center"}>로그인 사용자</TableColumn>
          <TableColumn align={"center"}>{ map.filteredExistsSnsId }명</TableColumn>
          <TableColumn align={"center"} percentage={existsSnsIdRating}>
            { map.filteredExistsSnsId === 0 ? 0 : existsSnsIdRating.toFixed() }%
          </TableColumn>
        </TableRow>
        <TableRow
          key={Math.random()}
          enableDetailButton={true}
          enableOverlay={false}
          onClickDetailButton={() => {}}>
          <TableColumn align={"center"}>비로그인 사용자</TableColumn>
          <TableColumn align={"center"}>{ map.filteredNotExistsSnsId }명</TableColumn>
          <TableColumn align={"center"} percentage={notExistsSnsIdRating}>
            { map.filteredNotExistsSnsId === 0 ? 0 : notExistsSnsIdRating.toFixed() }%
          </TableColumn>
        </TableRow>
        <TableRow
          key={Math.random()}
          enableDetailButton={true}
          enableOverlay={false}
          onClickDetailButton={() => {}}>
          <TableColumn align={"center"} rowSpan={2}>성별/연령 값 보유에 따른 접속자</TableColumn>
          <TableColumn align={"center"}>성별/연령 有 사용자 접속 수</TableColumn>
          <TableColumn align={"center"}>{ map.filteredExistsBirthYearAndGender }명</TableColumn>
          <TableColumn align={"center"} percentage={existsBirthYearAndGenderRating}>
            { map.filteredExistsBirthYearAndGender === 0 ? 0 : existsBirthYearAndGenderRating.toFixed() }%
          </TableColumn>
        </TableRow>
        <TableRow
          key={Math.random()}
          enableDetailButton={true}
          enableOverlay={false}
          onClickDetailButton={() => {}}>
          <TableColumn align={"center"}>성별/연령 無 사용자 접속 수</TableColumn>
          <TableColumn align={"center"}>{ map.filteredNotExistsBirthYearAndGender }명</TableColumn>
          <TableColumn align={"center"} percentage={notExistsBirthYearAndGenderRating}>
            { map.filteredNotExistsBirthYearAndGender === 0 ? 0 : notExistsBirthYearAndGenderRating.toFixed() }%
          </TableColumn>
        </TableRow>
      </PrimaryDataTable>
    </Container>
  )
}

export default AppMainUserStatisticsPresenter
