import { createContext, Dispatch, SetStateAction, useContext } from "react";
import { TendencyType } from "@enum";

export { default as UserTendency } from "./UserTendency"

export interface DetailAttribute {
  type: TendencyType
  ga: string
  snsId?: string
}

interface Context {
  detailAttribute: DetailAttribute
  setDetailAttribute: Dispatch<SetStateAction<DetailAttribute>>
  modalOpen: (type: TendencyType) => void
}

export const UserActivityTendencyContext = createContext<Context>({
  detailAttribute: {
    type: TendencyType.UNDEFINED,
    ga: '',
  },
  setDetailAttribute: () => {},
  modalOpen: () => {}
})

export const useUserActivityTendencyContext = () => useContext(UserActivityTendencyContext)

