import React from "react";
import {
  CustomSelect,
  CustomTextBox,
  Table,
  TableContainerStyle,
  TableRow,
  TableTitleStyle,
  Term,
  TermItem
} from "@component";
import TableSearchBox from "@component/Table/TableSearchBox";
import { useTableContext } from "@hook";
import styled from "styled-components";

interface Props {
  title: string
  headers: Array<string>
  isLoading: boolean
  children: React.ReactElement<typeof TableRow> | React.ReactElement<typeof TableRow>[]
}

const Styles = {
  Terms: styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 5px 10px;
  `
}

const ElasticsearchDataTable = ({ title, headers, isLoading, children }: Props) => {
  const {
    keywordState: [ , setKeyword ],
    searchTypesState: [ searchTypes, setSearchTypes ],
    pageState: [ , setPage ],
    termsState: [ terms, , , setTerms ]
  } = useTableContext()

  const handleChangeSearchInput = (value: string) => {
    setKeyword(value)
    setPage(0)
  }

  const handleRemoveTerm = ({ id }: TermItem) => {
    setTerms((prevState: Array<TermItem>) => {
      const newArray = prevState.filter(item => item.id !== id)
      return [...newArray]
    })
    setPage(0)
  }

  const handleChangeSelect = (key: string) => (value: string) => {
    setSearchTypes({
      ...searchTypes,
      [key]: value
    })
    setPage(0)
  }

  return (
    <TableContainerStyle>
      <TableTitleStyle>{ title }</TableTitleStyle>
      <TableSearchBox>
        <CustomSelect width={"150px"} label={"검색구분"} items={[
          {
            value: "ALL",
            label: "전체"
          },
          {
            value: "GA",
            label: "GA"
          },
          {
            value: "SNS_ID",
            label: "SNS_ID"
          },
          {
            value: "NICKNAME",
            label: "닉네임"
          }
        ]} onChange={handleChangeSelect('type')} />
        <CustomTextBox width={"300px"} onChange={handleChangeSearchInput} />
      </TableSearchBox>
      <Styles.Terms>
      {
        terms && terms.map((item: TermItem) => {
          return <Term item={item} onRemove={handleRemoveTerm} />
        })
      }
      </Styles.Terms>
      <Table
        xScroll={true}
        yScroll={true}
        isLoading={isLoading}
        enableKeyword={true}
        headers={headers}>
        { children }
      </Table>
    </TableContainerStyle>
  )
}

export default ElasticsearchDataTable
