import frontApiClient from "@lib/FrontApiClient";
import { ApiResponse } from "@type";

const fetchMatrix = (type: string, { query }: any) => async () => {
  const request = await frontApiClient.get(`/v1/matrix/${type}`, {
    params: { ...query }
  })
  const response: ApiResponse<Array<any>> = request.data
  return response.data
}

export default fetchMatrix
