import React, { ChangeEvent, useState } from "react";
import { TextField } from "@mui/material";
import styled from "styled-components";

const Styles = {
  TextBox: styled.div<{ width?: string }>`
    padding: 10px 5px;
    width: ${({ width }) => width ? width : ''}
  `
}

interface Props {
  width?: string
  defaultValue?: string
  onChange?: (value: string) => void
}

const CustomTextBox = ({ width, defaultValue, onChange }: Props) => {
  const [ currentValue, setCurrentValue ] = useState<string>(defaultValue || '')

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setCurrentValue(() => {
      onChange && onChange(value)
      return value
    })
  }

  return (
    <Styles.TextBox width={width}>
      <TextField fullWidth label="검색어" variant="outlined" defaultValue={defaultValue} onChange={handleChange} />
    </Styles.TextBox>
  )
}

export default CustomTextBox
