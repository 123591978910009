import React, { ReactNode } from "react";
import styled from "styled-components";

interface Props {
  children: ReactNode
}

const Styles = {
  Title: styled.h4`
    font-size: 22px;
  `
}

const TableTitle = ({ children }: Props) => {
  return (
    <Styles.Title className={"tableTitle"}>{ children }</Styles.Title>
  )
}

export default TableTitle
