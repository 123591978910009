import React from "react";

interface Props {
  width?: string
  height?: string
  maxHeight?: string
  minHeight?: string
  classNames?: Array<string>
  xScroll?: boolean
  yScroll?: boolean
  flexible?: boolean
  children?: JSX.Element | Array<JSX.Element>
}

const Container = ({ width, height = '100%', maxHeight, minHeight, classNames, xScroll = false, yScroll = false, flexible = false, children }: Props) => {
  return (
    <div
      className={["container", flexible ? "flexible" : null, classNames].filter(c => c !== null).join(" ")}
      style={{
        width: width || '100%',
        height,
        maxHeight,
        minHeight,
        overflowX: xScroll ? "auto" : "hidden",
        overflowY: yScroll ? "auto" : "hidden",
      }}>
      { children }
    </div>
  )
}

export default Container
