import styled from "styled-components";

export const TableTitleStyle = styled.h4`
  font-size: 22px;
  padding: 20px;
`

export const TableContainerStyle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`
