export enum Weekday {
  SUNDAY = "일요일",
  MONDAY = "월요일",
  TUESDAY = "화요일",
  WEDNESDAY = "수요일",
  THURSDAY = "목요일",
  FRIDAY = "금요일",
  SATURDAY = "토요일"
}

export function getWeekdayText(weekday: Weekday) {
  return Object.entries(Weekday)
    .filter(entry => entry[0] === weekday)
    .map(entry => entry[1])[0]
}
