import React, { ReactNode } from "react";
import { Button, Filter, InputDateTime, TableFilter } from "@component/index";
import styled from "styled-components";
import { usePopUpContext, useTableContext } from "@hook";
import { Moment } from "moment";
import { ColorType } from "@enum";

const Styles = {
  TableSearchBox: styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    position: relative;
  `,
  Left: styled.div`
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  `,
  Right: styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    
    & button {
      margin: 0 5px;
    }
  `
}

interface Props {
  dateType?: 'date' | 'datetime-local'
  children?: ReactNode
}

const TableSearchBox = ({ dateType = 'datetime-local', children }: Props) => {
  const { open } = usePopUpContext()
  const {
    fromDateTimeState: [ fromDateTime, setFromDateTime ],
    toDateTimeState: [ toDateTime, setToDateTime ],
    pageState: [ , setPage ],
    filtersState: [ filters, , , setFilters ]
  } = useTableContext()

  const handleChangeStartDateTime = (value: Moment) => {
    setFromDateTime(value)
    setPage(0)
  }

  const handleChangeEndDateTime = (value: Moment) => {
    setToDateTime(value)
    setPage(0)
  }

  const handleClickSearchIcon = () => {

  }

  const handleApplyFilter = (filter: Array<Filter>) => {
    setFilters(() => [...filter])
    setPage(0)
  }

  const handleClickFilter = () => open()

  return (
    <Styles.TableSearchBox>
      <Styles.Left>
        { children }
      </Styles.Left>
      <Styles.Right>
        <InputDateTime type={dateType} defaultValue={fromDateTime} onChange={handleChangeStartDateTime} />
        <InputDateTime type={dateType} defaultValue={toDateTime} onChange={handleChangeEndDateTime} />
        <Button
          icon={"search"}
          color={ColorType.PRIMARY}
          onClick={handleClickSearchIcon}
        />
        {
          filters && <Button
            show={filters && filters.length !== 0}
            icon={"filter_list"}
            color={ColorType.WHITE}
            onClick={handleClickFilter}
          />
        }
        {
          filters && <TableFilter
            onApply={handleApplyFilter}
            filters={filters}
          />
        }
      </Styles.Right>
    </Styles.TableSearchBox>
  )
}

export default TableSearchBox
