import resourceClient from "@lib/ResourceClient";
import { SimpleRegionInfo } from "@type";
import { AxiosResponse } from "axios";

const fetchRegionsJson: () => Promise<SimpleRegionInfo> = async () => {
  const request: AxiosResponse<SimpleRegionInfo>  = await resourceClient.get('/json/regions.json')
  return request.data
}

export default fetchRegionsJson
