import React from 'react';
import '@style/App.scss';
import {
  ActionTrackHistory,
  AreaRouteMatrix,
  CategoryStatistics,
  ContentStatistics,
  KeywordStatistics,
  PersonalizationStatistics,
  SearchAreaStatistics,
  TagStatistics,
  UserTendency,
  VisitAreaStatistics
} from "@page";
import { Route, Routes } from "react-router-dom";
import { PopUpContext, usePopUp } from "@hook";
import AppMainRealmStatistics from "@page/statistics/app-main/AppMainRealmStatistics";

function App() {
  const { isOpen, open, close } = usePopUp()
  return (
    <PopUpContext.Provider value={{ isOpen, open, close }}>
      <div className="app">
        <Routes>
          <Route path={"/statistics/app-main/:type"} element={<AppMainRealmStatistics />} />
          <Route path={"/statistics/ai"} element={<PersonalizationStatistics />} />
          <Route path={"/statistics/action-track"} element={<ActionTrackHistory />} />
          <Route path={"/statistics/search-area"} element={<SearchAreaStatistics />} />
          <Route path={"/statistics/visit-area"} element={<VisitAreaStatistics />} />
          <Route path={"/statistics/keyword"} element={<KeywordStatistics />} />
          <Route path={"/statistics/content"} element={<ContentStatistics />} />
          <Route path={"/statistics/tag"} element={<TagStatistics />} />
          <Route path={"/statistics/category"} element={<CategoryStatistics />} />
          <Route path={"/matrix/area-route"} element={<AreaRouteMatrix />} />
          <Route path={"/tendency"} element={<UserTendency />} />
        </Routes>
      </div>
    </PopUpContext.Provider>
  );
}

export default App;
