import React from "react";
import { Container, PrimaryDataTable, TableColumn, TableRow } from "@component";
import { Aggregations, Bucket, SearchResponse } from "@type";
import { getRegionList } from "@util";
import { useTableContext } from "@hook";

interface Props {
  data?: SearchResponse
  regions?: any
  isLoading: boolean
}

const VisitAreaStatisticsPresenter = ({ data, regions, isLoading }: Props) => {
  const { searchTypesState: [ searchTypes ] } = useTableContext()
  return (
    <Container>
      <PrimaryDataTable
        title={"방문 지역 성향"}
        pagination={false}
        headers={["방문 지역<br/>(광역시/도)", "방문 지역<br/>(시/군/구)", "누적횟수", "비율"]}
        selectItems={[
          {
            label: '지역구분',
            key: 'type',
            items: [
              {
                label: '1차 지역',
                value: 'SIDO'
              },
              {
                label: '2차 지역',
                value: 'SGG'
              }
            ]
          },
          {
            label: '광역시/도',
            key: 'sido',
            hidden: searchTypes.type !== 'SGG',
            items: getRegionList(false).map(({ text, value }) => ({
              label: text,
              value: value
            }))
          }
        ]}
        isLoading={isLoading}>
        {
          data ? data.aggregations.asMap[0].buckets
            .flatMap((bucket: Bucket, index) => {
              const { key: areaCode, docCount: count, aggregations } = bucket
              const percentage = count / data.hits.totalHits.value * 100
              const innerAggregations = aggregations?.asMap as Aggregations
              if (innerAggregations[1]?.buckets) {
                return innerAggregations[1].buckets
                  .map((_bucket: Bucket, _index) => {
                    const { key: sigunguCode, docCount: _count } = _bucket;
                    const percentage = _count / data.hits.totalHits.value * 100
                    return {
                      areaCode,
                      sigunguCode,
                      count: _count,
                      percentage
                    }
                  })
              }
              return {
                areaCode,
                sigunguCode: "0",
                count: count,
                percentage
              }
            })
            .sort((a, b) => a.count < b.count ? 1 : -1)
            .map((item, index) => {
              const { areaCode, sigunguCode, count, percentage } = item
              if (regions[areaCode] === undefined || areaCode === "0") {
                return <></>
              }
              return (
                <TableRow
                  key={index}
                  enableDetailButton={true}
                  enableOverlay={false}
                  onClickDetailButton={() => {}}>
                  <TableColumn align={"center"}>{ regions ? regions[areaCode].name : '-' }</TableColumn>
                  {
                    ['0', '99', 'All'].indexOf(sigunguCode) !== -1 ? (
                      <TableColumn align={"center"}>{regions[areaCode].name} 전체</TableColumn>
                    ) : (
                      <TableColumn align={"center"}>{ regions ? regions[areaCode][sigunguCode] ? regions[areaCode][sigunguCode].name : '-' : '-' }</TableColumn>
                    )
                  }
                  <TableColumn align={"center"}>{ count.toLocaleString() }건</TableColumn>
                  <TableColumn align={"center"} percentage={percentage}>{ percentage.toFixed(1) }%</TableColumn>
                </TableRow>
              )
            }) : <></>
        }
      </PrimaryDataTable>
    </Container>
  )
}

export default VisitAreaStatisticsPresenter
