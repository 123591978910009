import React, { useState } from "react";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import styled from "styled-components";

const Styles = {
  SelectBox: styled.div<{ width?: string }>`
    padding: 10px 5px;
    width: ${({ width }) => width ? width : ''}
  `
}

interface Props {
  width?: string
  label?: string
  defaultValue?: string
  items: Array<{ label: string, value: string }>
  onChange: (item: string) => void
}

const CustomSelect = ({ width, label, defaultValue, items, onChange }: Props) => {
  const [ value, setValue ] = useState<string>(defaultValue || items[0].value)

  const handleChange = (e: SelectChangeEvent) => {
    const { value } = e.target
    setValue(() => {
      onChange && onChange(value)
      return value
    })
  }

  return (
    <Styles.SelectBox width={width}>
      <FormControl fullWidth>
        <InputLabel>{ label }</InputLabel>
        <Select
          value={value}
          label={label}
          onChange={handleChange}>
          {
            items.map(({ label, value }) => (
              <MenuItem key={value} value={value}>{ label }</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </Styles.SelectBox>
  )
}

export default CustomSelect
