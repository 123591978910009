import { fetchUserTendencyAnalyze } from "@api";
import { UseQueryResult } from "react-query/types/react/types";
import { useQuery } from "react-query";

const useFetchUserQuery = (ga?: string, snsId?: string): UseQueryResult<any> => {
  return useQuery({
    retry: false,
    queryKey: [ 'fetchUserTendencyAnalyze', { ga, snsId } ],
    queryFn: ga === undefined || ga === '' ? () => {} : fetchUserTendencyAnalyze(ga, snsId)
  })
}

export default useFetchUserQuery


