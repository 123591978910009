import React from "react"

interface Props {
  children?: JSX.Element | Array<JSX.Element>
  flexible?: boolean
}

const PageTemplate = ({ children, flexible = false }: Props) => {
  return (
    <>
      <section className={["pageTemplate", "jumbotron", flexible ? "flexible" : null].filter(c => c !== null).join(" ")}>
        {children}
      </section>
    </>
  )
}

export default PageTemplate
