import { UserTendencyDetailModalPresenter } from "@presenter";
import React from "react";
import { useUserActivityTendencyContext } from "@page";
import { useFetchRegionJson } from "@hook/query/region/useFetchRegion";
import useFetchUserTendencyAnalyze from "@hook/query/user/useFetchUserTendencyAnalyze";
import { useTypeCodeQuery } from "@hook/query";

const UserTendencyDetailContainer = () => {
  const { detailAttribute: { ga, snsId } } = useUserActivityTendencyContext()
  const { data, isLoading } = useFetchUserTendencyAnalyze(ga, snsId)
  const regions = useFetchRegionJson()
  const types = useTypeCodeQuery(3)
  return (
    <UserTendencyDetailModalPresenter
      regions={regions.data}
      types={types.data}
      data={data}
      isLoading={isLoading}
    />
  )
}

export default UserTendencyDetailContainer
