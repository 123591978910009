import React from "react";
import { TableContext, useTable } from "@hook";
import { PageTemplate } from "@component";
import { CategoryStatisticsContainer } from "@container";
import moment from "moment/moment";

const CategoryStatistics = () => {
  const tableState = useTable({
    initial: {
      fromDateTime: moment().subtract(7, "days"),
      searchTypes: {
        condition: 'POSITIVE'
      }
    }
  })
  return (
    <TableContext.Provider value={tableState}>
      <PageTemplate>
        <CategoryStatisticsContainer />
      </PageTemplate>
    </TableContext.Provider>
  )
}

export default CategoryStatistics
