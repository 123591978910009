import React, { useState } from "react";
import { PageTemplate } from "@component";
import { ModalContext, TableContext, useModal, useTable } from "@hook"
import { UserTendencyContainer, UserTendencyDetailContainer } from "@container";
import { getRegionList } from "@util";
import { DetailAttribute, UserActivityTendencyContext } from "./"
import { TendencyType } from "@enum";

const UserTendency = () => {
  const modalStateKeyword = useModal()
  const modalStateArea = useModal()
  const modalStateTag = useModal()
  const modalStateContent = useModal()
  const modalStateVisit = useModal()
  const modalStateCategory = useModal()
  const modalStateSearchContent = useModal()
  const [ detailAttribute, setDetailAttribute ] = useState<DetailAttribute>({
    type: TendencyType.UNDEFINED,
    ga: ''
  })
  const modalOpen = (type: TendencyType) => {
    switch (type) {
      case TendencyType.KEYWORD:
        modalStateKeyword.open()
        break
      case TendencyType.AREA:
        modalStateArea.open()
        break
      case TendencyType.TAG:
        modalStateTag.open()
        break
      case TendencyType.CONTENT:
        modalStateContent.open()
        break
      case TendencyType.VISIT:
        modalStateVisit.open()
        break
      case TendencyType.CATEGORY:
        modalStateCategory.open()
        break
      case TendencyType.SEARCH_CONTENT:
        modalStateSearchContent.open()
        break
    }
  }

  const tableState = useTable({
    initial: {
      searchTypes: {
        type: 'ALL'
      },
      filters: [
        {
          text: "지역 성향 포함",
          key: "regionCodes",
          items: [ ...getRegionList() ]
        },
        {
          text: "값 존재 여부",
          key: "contains",
          items: [
            {
              text: '카테고리 성향 존재',
              value: 'CATEGORY'
            },
            {
              text: '콘텐츠 성향 존재',
              value: 'CONTENT'
            },
            {
              text: '키워드 성향 존재',
              value: 'KEYWORD'
            },
            {
              text: '태그 성향 존재',
              value: 'TAG'
            },
            {
              text: '지역 성향 존재',
              value: 'AREA'
            },
            {
              text: '방문 성향 존재',
              value: 'VISIT'
            },
            {
              text: 'AI 콕콕 플래너 코스 지역 성향 존재',
              value: 'COURSE_AREA'
            },
          ]
        }
      ]
    }
  })

  return (
    <UserActivityTendencyContext.Provider value={{ detailAttribute: detailAttribute, setDetailAttribute: setDetailAttribute, modalOpen: modalOpen }}>
      <ModalContext.Provider value={{
        modalKeyword: {
          ...modalStateKeyword
        },
        modalArea: {
          ...modalStateArea
        },
        modalTag: {
          ...modalStateTag
        },
        modalContent: {
          ...modalStateContent
        },
        modalVisit: {
          ...modalStateVisit
        },
        modalCategory: {
          ...modalStateCategory
        },
        modalSearchContent: {
          ...modalStateSearchContent
        },
      }}>
        <TableContext.Provider value={tableState}>
          <PageTemplate>
            <UserTendencyContainer />
            <UserTendencyDetailContainer />
          </PageTemplate>
        </TableContext.Provider>
      </ModalContext.Provider>
    </UserActivityTendencyContext.Provider>
  )
}

export default UserTendency
