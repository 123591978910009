import React from "react";
import { UseQueryResult } from "react-query/types/react/types";
import { SimpleTagInfo } from "@type";
import { useQuery } from "react-query";
import { fetchRegionsJson, fetchTagsJson } from "@api";
import * as QueryBuilder from "@util";
import { useTableContext } from "@hook"
import { ActionTrackPresenter } from "@presenter";
import { FilterItem, TermItem } from "@component";
import useFetchUserQuery from "../../hook/query/user/useFetchUserQuery";
import { useFetchElasticsearchQueryProxy } from "@hook/query/elasticsearch";

const ActionTrackContainer = () => {
  const DEFAULT_PAGING_SIZE = 500
  const {
    keywordState: [ keyword ],
    fromDateTimeState: [ fromDateTime ],
    toDateTimeState: [ toDateTime ],
    pageState: [ page ],
    filtersState: [ filters ],
    termsState: [ terms ],
    searchTypesState: [ searchTypes ],
  } = useTableContext()

  const tags: UseQueryResult<Array<SimpleTagInfo>> =
    useQuery([ 'static-tags' ], fetchTagsJson, { retry: false })

  const regions: UseQueryResult<any> =
    useQuery([ 'static-regions' ], fetchRegionsJson, { retry: false })

  const users: UseQueryResult<any> = useFetchUserQuery(keyword, searchTypes.type === 'NICKNAME')

  const createQueryByTypeAndKeyword = (searchType: string, keyword?: string) => {
    const array = []
    if (keyword) {
      if (searchType === 'GA' || searchType === 'ALL') {
        array.push({
          "match_phrase": {
            "action_info.ga": keyword
          }
        })
      }
      if (searchType === 'SNS_ID' || searchType === 'ALL') {
        array.push({
          "match_phrase": {
            "action_info.snsId": keyword
          }
        })
      }
      if (searchType === 'NICKNAME') {
        if (users) {
          array.push({
            "bool": {
              "should": users.data?.map((user: { id: any; }) => {
                return {
                  "match_phrase": {
                    "action_info.snsId": user.id
                  }
                }
              })
            }
          })
        }
      }
    }
    return searchType === 'ALL' ? [{
      "bool": {
        "should": array
      }
    }] : array
  }

  const { data, isLoading } = useFetchElasticsearchQueryProxy("action-track*", QueryBuilder.createQuery({
    page: page,
    size: DEFAULT_PAGING_SIZE,
    gte: fromDateTime,
    lte: toDateTime,
    filter: searchTypes.type ? [ ...createQueryByTypeAndKeyword(searchTypes.type, keyword) ] : [],
    should: [
      ...filters[0].items.filter((item: FilterItem) => item.checked).map((item: FilterItem) => ({
        "match_phrase": {
          "action_info.type": item.value
        }
      })),
      ...terms.map((item: TermItem) => {
        const { key, value } = item
        return {
          "match_phrase": {
            [key]: value
          }
        }
      }),
    ],
  }))

  return (
    <ActionTrackPresenter
      isLoading={isLoading}
      data={data}
      users={users.data}
      tags={tags.data}
      regions={regions.data}
    />
  )
}

export default ActionTrackContainer
