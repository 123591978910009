import frontApiClient from "@lib/FrontApiClient";
import { ApiResponse, Code } from "@type";
import frontClient from "@lib/FrontClient";

export interface FetchAreaCodeItem {
  areaCode: number
  areaName: string
  sigunguCode: number
  sigunguName: string
}

export const fetchAreaCode = async () => {
  const request = await frontClient.post('', JSON.stringify({
    cmd: "RECOM_SIGUNGU_CODE"
  }))
  const response: Array<FetchAreaCodeItem> = request.data.body.result
  return response
}

export const fetchCodeList = (type: number) => async () => {
  const request = await frontApiClient.get(`/v1/search/code`, {
    params: { type }
  })
  const response: ApiResponse<Array<Code>> = request.data
  return response.data
}
