import React, { useEffect, useState } from "react";
import { Container, PrimaryDataTable, TableColumn, TableRow } from "@component";
import { Aggregations, Bucket, Code, SearchResponse } from "@type";

interface Props {
  data?: SearchResponse
  dataCount?: SearchResponse
  types?: Array<Code>
  isLoading: boolean
}

const AppMainRealmStatisticsPresenter = ({ data, dataCount, types, isLoading }: Props) => {
  const initialArray = [
    { typeCode: 'C', testType: '가볼래터', percentage: 0, event: '-', consume: '-', typeName: '알림 영역' },
    { typeCode: 'C', testType: '디지털관광주민증', percentage: 0, event: '-', consume: '-', typeName: '알림 영역' },
    { typeCode: 'C', testType: '배지획득', percentage: 0, event: '-', consume: '-', typeName: '알림 영역' },
    { typeCode: 'C', testType: '소식', percentage: 0, event: '-', consume: '-', typeName: '알림 영역' },
    { typeCode: 'C', testType: '여행캘린더', percentage: 0, event: '-', consume: '-', typeName: '알림 영역' },
    { typeCode: 'E', testType: 'A', percentage: 0, event: '-', consume: '-', testTypeName: '최근 본 여행지와 유사한 여행지 추천' },
    { typeCode: 'E', testType: 'B', percentage: 0, event: '-', consume: '-', testTypeName: '이런 여행지 어때요?' },
    { typeCode: 'F', testType: '-', percentage: 0, event: '-', consume: '-', testTypeName: '스페셜관' },
    { typeCode: 'G', testType: 'A-1', percentage: 0, event: '-', consume: '-', testTypeName: 'A 데이터', typeName: '맞춤(알아서) 추천' },
    { typeCode: 'G', testType: 'A-2', percentage: 0, event: '-', consume: '-', testTypeName: 'B 데이터', typeName: '맞춤(알아서) 추천' },
    { typeCode: 'G', testType: 'B', percentage: 0, event: '-', consume: '-', testTypeName: 'C 데이터', typeName: '맞춤(알아서) 추천' },
    { typeCode: 'H', testType: '-', percentage: 0, event: '-', consume: '-', testTypeName: '당신이 좋아할만한 여행지' },
    { typeCode: 'I', testType: '-', percentage: 0, event: '-', consume: '-', testTypeName: '내주변 여행지 추천' },
    { typeCode: 'J', testType: '-', percentage: 0, event: '-', consume: '-', testTypeName: '나의 관심 지역 추천 여행지' },
    { typeCode: 'M', testType: 'A', percentage: 0, event: '-', consume: '-', testTypeName: '성별 인기 여행지' },
    { typeCode: 'M', testType: 'B', percentage: 0, event: '-', consume: '-', testTypeName: '연령별 인기 여행지' },
    { typeCode: 'TOPIC_PLACE', testType: '-', percentage: 0, event: '-', consume: '-', testTypeName: '이달의 화제 여행지' },
    { typeCode: 'APP_MAIN_OCM', testType: '-', percentage: 0, event: '-', consume: '-', testTypeName: '컴포넌트 영역 합' },
  ]
  const [ array, setArray ] = useState<Array<any>>(initialArray)

  useEffect(() => {
    if (data) {
      const cloneArray = [ ...initialArray ]
      const findItem = (compareTypeCode: string, compareTestType: string) => {
        return cloneArray.find(({ typeCode, testType }) => {
          return typeCode === compareTypeCode && testType === compareTestType
        })
      }
      // const array: any[] = []
      getAggregationsBuckets(data.aggregations).forEach((pageBucket: Bucket) =>
        getAggregationsBuckets(pageBucket.aggregations).forEach((typeNumberBucket: Bucket) =>
          getAggregationsBuckets(typeNumberBucket.aggregations).forEach((testTypeBucket: Bucket) => {
            const traceTypeBuckets = getAggregationsBuckets(testTypeBucket.aggregations)
            const consumeBucket = traceTypeBuckets.filter((bucket: Bucket) => bucket.keyAsString === 'CONSUME')
            const eventBucket = traceTypeBuckets.filter((bucket: Bucket) => bucket.keyAsString === 'EVENT')
            const consume = consumeBucket && consumeBucket.length !== 0 ? consumeBucket[0].docCount : 0
            const event = eventBucket && eventBucket.length !== 0 ? eventBucket[0].docCount : 0
            const percentage = consume / event * 100
            const item = findItem(typeNumberBucket.keyAsString, testTypeBucket.keyAsString)
            if (item) {
              item.percentage = percentage
              item.event = event === 0 ? '-' : `${event}건`
              item.consume = consume === 0 ? '-' : `${consume}건`
            }
          }))
        )
      console.log('cloneArray', cloneArray)
      setArray(() => cloneArray)
    }
  }, [ data ])

  const getAggregationsBuckets = (aggregations: any) => {
    const aggregationsAsMap = aggregations.asMap as Aggregations
    return aggregationsAsMap[0].buckets.sort(sortAscending)
  }

  const sortAscending = (a: Bucket, b: Bucket) => a.keyAsString > b.keyAsString ? 1 : -1
  return (
    <Container>
      <PrimaryDataTable
        title={"APP 메인 통계 > 영역별 소비 통계"}
        pagination={false}
        headers={["세부 영역", "영역명", "제공 데이터", "클릭(소비) 수", "노출 이벤트 수", "소비 비율"]}
        useKeyword={false}
        dateType={'datetime-local'}
        isLoading={isLoading}>
        {
          array && array.length !== 0 ? (() => {
            return array.map((item, index) => {
              const { typeCode, typeName, testTypeName, testType, consume, event, percentage } = item
              const isFirst = index === 0
              const isDifferenceBefore = () => array[index - 1].typeCode !== typeCode
              const equalsTypeNumberLength = array.filter(item => item.typeCode === typeCode).length
              const isNotEmptyTextType = () => ['C', 'G'].indexOf(typeCode) !== -1
              return (
                <TableRow
                  key={Math.random()}
                  enableDetailButton={true}
                  enableOverlay={false}
                  onClickDetailButton={() => {}}>
                  {(() => {
                    if (isFirst || isDifferenceBefore()) {
                      if (typeName !== undefined) {
                        return (
                          <>
                            <TableColumn align={"center"} rowSpan={equalsTypeNumberLength}>
                              {typeCode}
                            </TableColumn>
                            <TableColumn align={"center"} rowSpan={equalsTypeNumberLength} colSpan={1}>
                              {typeName}
                            </TableColumn>
                          </>
                        )
                      } else {
                        return (
                          <TableColumn align={"center"} rowSpan={equalsTypeNumberLength}>
                            {typeCode}
                          </TableColumn>
                        )
                      }
                    }
                    return <></>
                  })()}
                  <TableColumn align={"center"} colSpan={isNotEmptyTextType() ? 1 : 2}>
                    {testTypeName ? testTypeName : testType}
                  </TableColumn>
                  <TableColumn align={"center"}>{ consume }</TableColumn>
                  <TableColumn align={"center"}>{ event }</TableColumn>
                  {
                    percentage === 0 ? (
                      <TableColumn align={"center"}>{ '-' }</TableColumn>
                    ) : (
                      <TableColumn align={"center"} percentage={percentage}>{ percentage === 0 ? '-' : `${percentage.toFixed(1)}%` }</TableColumn>
                    )
                  }
                </TableRow>
              )
            })
          })() : <></>
        }
      </PrimaryDataTable>
    </Container>
  )
}

export default AppMainRealmStatisticsPresenter
