import { useQuery, UseQueryResult } from "react-query";
import { FetchAreaCodeItem, fetchUser } from "@api";

const useFetchUserQuery = (keyword: string, enable?: boolean): UseQueryResult<Array<FetchAreaCodeItem>> => {
  return useQuery({
    retry: false,
    queryKey: [ 'fetch-user', { keyword } ],
    enabled: keyword !== undefined && enable,
    queryFn: fetchUser(keyword)
  })
}

export default useFetchUserQuery
