import React from "react";
import { KeywordStatisticsPresenter } from "@presenter";
import { UseQueryResult } from "react-query/types/react/types";
import { SearchResponse } from "@type";
import { useQuery } from "react-query";
import { fetchElasticsearchQueryProxy } from "@api";
import * as QueryBuilder from "@util";
import { useTableContext } from "@hook";

const KeywordStatisticsContainer = () => {
  const DEFAULT_PAGING_SIZE = 0
  const {
    keywordState: [ keyword ],
    fromDateTimeState: [ fromDateTime ],
    toDateTimeState: [ toDateTime ],
    searchTypesState: [ searchTypes ],
  } = useTableContext()

  const { data, isLoading }: UseQueryResult<SearchResponse> = useQuery({
    retry: false,
    queryKey: [ 'keyword-statistics', { keyword, fromDateTime, toDateTime, searchTypes }],
    queryFn: fetchElasticsearchQueryProxy(QueryBuilder.createQuery({
      size: DEFAULT_PAGING_SIZE,
      gte: fromDateTime,
      lte: toDateTime,
      aggs: {
        0: {
          "terms": {
            "field": "action_info.keyword.keyword",
            "size": 50
          },
        }
      },
      mustNot: (() => {
        const array: any = []
        if (searchTypes.type !== 'SEARCH') {
          array.push({
            "match_phrase": {
              "action_info.searchtype": "SEARCH"
            }
          })
        }
        return array
      })(),
      should: (() => {
        const array: any = []
        return array
      })(),
      filter: (() => {
        let array: any = [
          {
            "exists": {
              "field": "action_info.keyword.keyword"
            },
          },
        ]
        if (searchTypes.type === 'SEARCH') {
          array = [
            ...array,
            {
              "match_phrase": {
                "action_info.searchtype": "SEARCH"
              }
            }
          ]
        }
        if (keyword !== undefined && keyword !== '') {
          array = [
            ...array,
            {
              "regexp": {
                "action_info.keyword.keyword": `.*${keyword}.*`
              }
            }
          ]
        }
        return array
      })()
    }), "action-track*", true)
  })

  return (
    <KeywordStatisticsPresenter isLoading={isLoading} data={data} />
  )
}

export default KeywordStatisticsContainer
