import axios from "axios";

const frontClient = (() => {
  const client = axios.create({
    baseURL: 'https://korean.visitkorea.or.kr/call',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
  return client
})()

export default frontClient
