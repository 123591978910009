import React from "react";
import { TableContext, useTable } from "@hook";
import { PageTemplate } from "@component";
import { KeywordStatisticsContainer } from "@container";

const KeywordStatistics = () => {
  const tableState = useTable({
    initial: {
      searchTypes: {
        type: 'SEARCH'
      }
    }
  })
  return (
    <TableContext.Provider value={tableState}>
      <PageTemplate>
        <KeywordStatisticsContainer />
      </PageTemplate>
    </TableContext.Provider>
  )
}

export default KeywordStatistics
