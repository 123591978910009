export enum SnsType {
  FACEBOOK = 0,
  TWITTER = 1,
  INSTAGRAM = 2,
  DAUM = 3,
  NAVER = 4,
  KAKAOTALK = 5,
  GOOGLE = 6,
  PARTNERS = 10
}

export const getByNumber = (typeNumber: number) => {
  return Object.entries(SnsType).filter(array => {
    return array[0] === `${typeNumber}`
  }).map(array => array[1])
}
