import React, { useState } from "react";
import styled from "styled-components";

interface Props {
  rowSpan?: number
  colSpan?: number
  align?: "left" | "center" | "right"
  maxWidth?: string
  children?: any
  searchEnabled?: boolean
  onSearch?: () => void
  percentage?: number
  url?: string
  description?: {
    enable?: boolean
    onClick?: () => void
  }
}

const Styles = {
  TableColumn: styled.td<{ maxWidth?: string }>`
    max-width: ${({ maxWidth }) => maxWidth};
  `,
  Percentage: styled.span<{ width?: string }>`
    width: ${({ width }) => width};
    box-sizing: border-box;
    background-color: #2196f3;
    top: 0;
    left: 0;
    opacity: 0.3;
    position: absolute;
    height: 100%;
    max-width: 100%;
  `
}
const TableColumn = ({ rowSpan = 1, colSpan = 1, align = "left", maxWidth, children, searchEnabled, onSearch, percentage, url, description }: Props) => {
  const [ underline, setUnderline ] = useState<boolean>()

  const handleSearch = () => {
    onSearch && onSearch()
  }

  const handleClick = () => {
    if (url) {
      window.open(url)
    }
  }

  const handleMouseOver = (e: any) => {
    if (url) {
      e.currentTarget.style.textDecoration = 'underline'
    }
  }

  const handleMouseLeave = (e: any) => {
    if (url) {
      e.currentTarget.style.textDecoration = 'none'
    }
  }

  return (
    <td rowSpan={rowSpan} colSpan={colSpan} className={"tableColumn"} align={align} style={maxWidth ? { maxWidth: maxWidth } : {}}>
      {
        percentage && percentage > 0 ? (
          <Styles.Percentage width={`${percentage < 100 ? percentage : 100}%`}></Styles.Percentage>
        ) : <></>
      }
      <span style={{ justifyContent: align }}>
        {
          url ? (
            <span
              onClick={handleClick}
              onMouseOver={handleMouseOver}
              onMouseLeave={handleMouseLeave}>
              { children }
            </span>
          ) : children
        }
        {
          searchEnabled && <i onClick={handleSearch} className={"material-icons"} style={{ fontSize: "14px", marginLeft: "5px", color: "grey" }}>search</i>
        }
        {
          description?.enable && (
            <i className={"material-icons"}
               onClick={description?.onClick}
               style={{ fontSize: "14px", marginLeft: "5px", color: "grey" }}>description</i>
          )
        }
        {
          url && <i className={"material-icons"} style={{ fontSize: "14px", marginLeft: "5px", color: "grey" }}>open_in_new</i>
        }
      </span>
    </td>
  )
}

export default TableColumn
