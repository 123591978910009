import React from "react";
import { UserTendencyPresenter } from "@presenter";
import { UseQueryResult } from "react-query/types/react/types";
import { useQuery } from "react-query";
import { useTableContext } from "@hook";
import { fetchRegionsJson, fetchTagsJson } from "@api";
import { Filter, FilterItem } from "@component";
import * as QueryBuilder from "@util";
import { useFetchElasticsearchQueryProxy } from "@hook/query/elasticsearch";
import useFetchUserQuery from "../../hook/query/user/useFetchUserQuery";
import { Code, SimpleTagInfo } from "@type";
import { useTypeCodeQuery } from "@hook/query";

const UserTendencyContainer = () => {
  const DEFAULT_PAGING_SIZE = 20
  const {
    pageState: [ page ],
    keywordState: [ keyword ],
    filtersState: [ filters ],
    searchTypesState: [ searchTypes ],
  } = useTableContext()

  const users: UseQueryResult<any> = useFetchUserQuery(keyword, searchTypes.type === 'NICKNAME')

  const types: UseQueryResult<Array<Code>> = useTypeCodeQuery(3)

  const createQueryByTypeAndKeyword = (searchType: string, keyword?: string) => {
    const array = []
    if (keyword) {
      if (searchType === 'GA' || searchType === 'ALL') {
        array.push({
          "match_phrase": {
            "ga": keyword
          }
        })
      }
      if (searchType === 'SNS_ID' || searchType === 'ALL') {
        array.push({
          "match_phrase": {
            "snsId": keyword
          }
        })
      }
      if (searchType === 'NICKNAME') {
        if (users) {
          array.push({
            "bool": {
              "should": users.data?.map((user: { id: any; }) => {
                return {
                  "match_phrase": {
                    "snsId": user.id
                  }
                }
              })
            }
          })
        }
      }
    }
    return searchType === 'ALL' ? [ {
      "bool": {
        "should": array
      }
    } ] : array
  }

  const { data, isLoading } = useFetchElasticsearchQueryProxy("user-tendency", QueryBuilder.createQuery({
    page: page,
    size: DEFAULT_PAGING_SIZE,
    collapse: {
      "field": "userKey.keyword",
    },
    filter: keyword ? [ ...createQueryByTypeAndKeyword(searchTypes.type, keyword) ] : [],
    should: [ ...getFilters(filters) ],
  }))

  const tags: UseQueryResult<Array<SimpleTagInfo>> =
    useQuery([ 'static-tags' ], fetchTagsJson, { retry: false })

  const regions: UseQueryResult<any> =
    useQuery([ 'static-regions' ], fetchRegionsJson, {
      retry: false,
      refetchOnWindowFocus: false
    })

  return (
    <UserTendencyPresenter
      data={data}
      isLoading={isLoading}
      regions={regions.data}
      tags={tags.data}
      types={types.data}
    />
  )
}

const getFilters = (filters: Array<Filter>) => {
  return filters.flatMap((filter: Filter) => {
    return filter.items.filter((item: FilterItem) => item.checked).flatMap(item => {
      const { value } = item
      if (value === 'CONTENT') {
        return [
          {
            "exists": {
              field: "tendency.content.positive.value"
            }
          },
          {
            "exists": {
              field: "tendency.content.negative.value"
            }
          }
        ]
      }
      if (value === 'CATEGORY') {
        return [
          {
            "exists": {
              field: "tendency.category.positive.value"
            }
          },
          {
            "exists": {
              field: "tendency.category.negative.value"
            }
          }
        ]
      }
      if (value === 'TAG') {
        return [
          {
            "exists": {
              field: "tendency.tag.positive.value"
            }
          },
          {
            "exists": {
              field: "tendency.tag.negative.value"
            }
          }
        ]
      }
      if (value === 'VISIT') {
        return [
          {
            "exists": {
              field: "tendency.visit.value"
            }
          },
        ]
      }
      if (value === 'KEYWORD') {
        return [
          {
            "exists": {
              field: "tendency.keyword.value"
            }
          },
        ]
      }
      if (value === 'AREA') {
        return [
          {
            "exists": {
              field: "tendency.area.value"
            }
          },
        ]
      }
      if (value === 'COURSE_AREA') {
        return [
          {
            "exists": {
              field: "tendency.courseArea.value"
            }
          },
        ]
      }
    })
  })
}

export default UserTendencyContainer
