import axios from "axios";

const frontApiClient = (() => {
  const client = axios.create({
    baseURL: 'https://korean.visitkorea.or.kr/api',
    // baseURL: 'https://stage.visitkorea.or.kr/api',
    // baseURL: 'https://dev.ktovisitkorea.com/api',
    // baseURL: 'http://localhost:7777/api',
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return client
})()

export default frontApiClient
