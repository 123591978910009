import React from "react";
import { PageTemplate } from "@component";
import { ActionType } from "@enum";
import { TableContext, useTable } from "@hook"
import { ActionTrackContainer } from "@container";

const ActionTrackHistory = () => {
  const tableState = useTable({
    initial: {
      filters: [
        {
          text: "활동구분",
          key: "type",
          items: Object.entries(ActionType).map(entry => ({
            text: entry[1],
            value: entry[0]
          }))
        }
      ],
      searchTypes: {
        type: 'ALL'
      }
    }
  })

  return (
    <TableContext.Provider value={tableState}>
      <PageTemplate>
        <ActionTrackContainer />
      </PageTemplate>
    </TableContext.Provider>
  )
}

export default ActionTrackHistory
