import React from "react";
import { UseQueryResult } from "react-query/types/react/types";
import { Code, SearchResponse } from "@type";
import * as QueryBuilder from "@util";
import { useTableContext } from "@hook";
import useFetchElasticsearchQueryProxy from "../../hook/query/elasticsearch/useFetchElasticsearchQueryProxy";
import { AppMainRealmStatisticsPresenter, AppMainUserStatisticsPresenter } from "@presenter";
import { useTypeCodeQuery } from "@hook/query";

const AppMainUserStatisticsContainer = () => {
  const DEFAULT_PAGING_SIZE = 0
  const {
    fromDateTimeState: [ fromDateTime ],
    toDateTimeState: [ toDateTime ],
    searchTypesState: [ searchTypes ],
  } = useTableContext()

  const types: UseQueryResult<Array<Code>> = useTypeCodeQuery(3)

  const createQuery = (aggs: any) => {
    return QueryBuilder.createQuery({
      size: DEFAULT_PAGING_SIZE,
      gte: fromDateTime,
      lte: toDateTime,
      aggs: aggs,
      filter: (() => {
        return [
          {
            "match_phrase": {
              "action_info.type": "APP_MAIN_VIEW"
            }
          }
        ]
      })()
    })
  }

  const { data, isLoading } : UseQueryResult<SearchResponse> = useFetchElasticsearchQueryProxy("action-track*", createQuery({
    0: {
      "filters": {
        "filters": {
          "filteredExistsSnsId": {
            "bool": {
              "must": [],
              "filter": [
                {
                  "bool": {
                    "should": [
                      {
                        "exists": {
                          "field": "action_info.snsId.keyword"
                        }
                      }
                    ],
                    "minimum_should_match": 1
                  }
                }
              ],
              "should": [],
              "must_not": []
            }
          },
          "filteredNotExistsSnsId": {
            "bool": {
              "must": [],
              "filter": [
                {
                  "bool": {
                    "must_not": {
                      "bool": {
                        "should": [
                          {
                            "exists": {
                              "field": "action_info.snsId.keyword"
                            }
                          }
                        ],
                        "minimum_should_match": 1
                      }
                    }
                  }
                }
              ],
              "should": [],
              "must_not": []
            }
          },
          "filteredExistsBirthYearAndGender": {
            "bool": {
              "must": [],
              "filter": [
                {
                  "bool": {
                    "filter": [
                      {
                        "bool": {
                          "should": [
                            {
                              "exists": {
                                "field": "action_info.birthyear"
                              }
                            }
                          ],
                          "minimum_should_match": 1
                        }
                      },
                      {
                        "bool": {
                          "should": [
                            {
                              "exists": {
                                "field": "action_info.gender"
                              }
                            }
                          ],
                          "minimum_should_match": 1
                        }
                      }
                    ]
                  }
                }
              ],
              "should": [],
              "must_not": []
            }
          },
          "filteredNotExistsBirthYearAndGender": {
            "bool": {
              "must": [],
              "filter": [
                {
                  "bool": {
                    "filter": [
                      {
                        "bool": {
                          "must_not": {
                            "bool": {
                              "should": [
                                {
                                  "exists": {
                                    "field": "action_info.birthyear"
                                  }
                                }
                              ],
                              "minimum_should_match": 1
                            }
                          }
                        }
                      },
                      {
                        "bool": {
                          "must_not": {
                            "bool": {
                              "should": [
                                {
                                  "exists": {
                                    "field": "action_info.gender"
                                  }
                                }
                              ],
                              "minimum_should_match": 1
                            }
                          }
                        }
                      }
                    ]
                  }
                }
              ],
              "should": [],
              "must_not": []
            }
          }
        }
      },
      "aggs": {
        "userCount": {
          "cardinality": {
            "field": "ga.keyword"
          }
        }
      }
    }
  }), false)

  return (
    <AppMainUserStatisticsPresenter
      isLoading={isLoading}
      data={data}
      types={types.data}
    />
  )
}

export default AppMainUserStatisticsContainer
