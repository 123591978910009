import resourceClient from "@lib/ResourceClient";
import { AxiosResponse } from "axios";
import { SimpleTagInfo } from "@type";

const fetchTagsJson: () => Promise<Array<SimpleTagInfo>> = async () => {
  const request: AxiosResponse<Array<SimpleTagInfo>> = await resourceClient.get('/json/tags.json')
  return request.data
}

export default fetchTagsJson
