import React from "react";
import { UseQueryResult } from "react-query/types/react/types";
import { Code, SearchResponse } from "@type";
import * as QueryBuilder from "@util";
import { useTableContext } from "@hook";
import useFetchElasticsearchQueryProxy from "../../hook/query/elasticsearch/useFetchElasticsearchQueryProxy";
import { AppMainRealmStatisticsPresenter } from "@presenter";
import { useTypeCodeQuery } from "@hook/query";

const AppMainRealmStatisticsContainer = () => {
  const DEFAULT_PAGING_SIZE = 0
  const {
    keywordState: [ keyword ],
    fromDateTimeState: [ fromDateTime ],
    toDateTimeState: [ toDateTime ],
    searchTypesState: [ searchTypes ],
  } = useTableContext()

  const types: UseQueryResult<Array<Code>> = useTypeCodeQuery(3)

  const createQuery = (aggs: any) => {
    return QueryBuilder.createQuery({
      size: DEFAULT_PAGING_SIZE,
      gte: fromDateTime,
      lte: toDateTime,
      aggs: aggs,
      filter: (() => {
        return [
          {
            "terms": {
              "page.keyword": ["app_main"]
            }
          },
          {
            "terms": {
              "traceType.keyword": ["EVENT", "CONSUME"]
            }
          },
          {
            "regexp": {
              "eventId.keyword": "[a-zA-Z0-9]{12}"
            }
          },
          {
            "bool": {
              "should": [
                {
                  "bool": {
                    "filter": [
                      {
                        "match_phrase": {
                          "typeName.keyword": "C"
                        }
                      }
                    ]
                  }
                },
                {
                  "bool": {
                    "filter": [
                      {
                        "match_phrase": {
                          "typeName.keyword": "E"
                        }
                      },
                      {
                        "terms": {
                          "testType.keyword": [
                            "A",
                            "B"
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  "bool": {
                    "filter": [
                      {
                        "match_phrase": {
                          "typeName.keyword": "F"
                        }
                      }
                    ]
                  }
                },
                {
                  "bool": {
                    "filter": [
                      {
                        "match_phrase": {
                          "typeName.keyword": "G"
                        }
                      },
                      {
                        "terms": {
                          "testType.keyword": [
                            "A-1",
                            "A-2",
                            "B"
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  "bool": {
                    "filter": [
                      {
                        "match_phrase": {
                          "typeName.keyword": "H"
                        }
                      }
                    ]
                  }
                },
                {
                  "bool": {
                    "filter": [
                      {
                        "match_phrase": {
                          "typeName.keyword": "I"
                        }
                      }
                    ]
                  }
                },
                {
                  "bool": {
                    "filter": [
                      {
                        "match_phrase": {
                          "typeName.keyword": "J"
                        }
                      }
                    ]
                  }
                },
                {
                  "bool": {
                    "filter": [
                      {
                        "match_phrase": {
                          "typeName.keyword": "M"
                        }
                      },
                      {
                        "terms": {
                          "testType.keyword": [
                            "A",
                            "B"
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  "bool": {
                    "filter": [
                      {
                        "match_phrase": {
                          "typeName.keyword": "TOPIC_PLACE"
                        }
                      }
                    ]
                  }
                },
                {
                  "bool": {
                    "filter": [
                      {
                        "match_phrase": {
                          "typeName.keyword": "APP_MAIN_OCM"
                        }
                      }
                    ]
                  }
                },
              ]
            }
          }
        ]
      })()
    })
  }

  const { data, isLoading } : UseQueryResult<SearchResponse> = useFetchElasticsearchQueryProxy("trace-ai*", createQuery({
    0: {
      "terms": {
        "field": "page.keyword",
        "order": {
          "_count": "desc"
        },
        "size": 5
      },
      "aggs": {
        0: {
          "terms": {
            "field": "typeName.keyword",
            "order": {
              "_count": "asc"
            },
            "missing": "-",
            "size": 20
          },
          "aggs": {
            0: {
              "terms": {
                "field": "testType.keyword",
                "order": {
                  "_count": "desc"
                },
                "missing": "-",
                "size": 5
              },
              "aggs": {
                0: {
                  "terms": {
                    "field": "traceType.keyword",
                    "order": {
                      "_count": "desc"
                    },
                    "missing": "-",
                    "size": 5
                  }
                }
              }
            }
          }
        }
      }
    }
  }), false)

  return (
    <AppMainRealmStatisticsPresenter
      isLoading={isLoading}
      data={data}
      types={types.data}
    />
  )
}

export default AppMainRealmStatisticsContainer
