import React from "react";
import { UseQueryResult } from "react-query/types/react/types";
import { SearchResponse } from "@type";
import { useQuery } from "react-query";
import { fetchElasticsearchQueryProxy, fetchRegionsJson } from "@api";
import * as QueryBuilder from "@util";
import { useTableContext } from "@hook";
import VisitAreaStatisticsPresenter from "../../presenter/statistics/VisitAreaStatisticsPresenter";

const VisitAreaStatisticsContainer = ({ }) => {
  const DEFAULT_PAGING_SIZE = 0
  const {
    fromDateTimeState: [ fromDateTime ],
    toDateTimeState: [ toDateTime ],
    searchTypesState: [ searchTypes ],
  } = useTableContext()

  const regions: UseQueryResult<any> =
    useQuery([ 'static-regions' ], fetchRegionsJson, { retry: false })

  const { data, isLoading }: UseQueryResult<SearchResponse> = useQuery({
    retry: false,
    queryKey: [ 'visit-area-statistics', { fromDateTime, toDateTime, searchTypes }],
    queryFn: fetchElasticsearchQueryProxy(QueryBuilder.createQuery({
      size: DEFAULT_PAGING_SIZE,
      gte: fromDateTime,
      lte: toDateTime,
      aggs: {
        0: {
          ...searchTypes.type === 'SGG' ? {
            "aggs": {
              1: {
                "terms": {
                  "field": "action_info.sigungucode.keyword",
                  "order": {
                    "_count": "desc"
                  },
                  "size": 100
                }
              }
            }
          }: {},
          "terms": {
            "field": "action_info.areacode.keyword",
            "order": {
              "_count": "desc"
            },
            "missing": "0",
            "size": 50
          },
        }
      },
      should: (() => {
        let array: any = [
          {
            "match_phrase": {
              "action_info.type": "NAVI"
            }
          },
          {
            "match_phrase": {
              "action_info.type": "STAMP"
            }
          },
          {
            "match_phrase": {
              "action_info.type": "UPLOAD_PHOTO"
            }
          },
        ]
        return array
      })(),
      filter: (() => {
        let array: any = []
        if (searchTypes.type === 'SGG' && searchTypes.sido && searchTypes.sido !== "0") {
          array = [
            ...array,
            {
              "match_phrase": {
                "action_info.areacode": searchTypes.sido
              }
            }
          ]
        }
        return array
      })()
    }), "action-track*", true)
  })

  return (
    <VisitAreaStatisticsPresenter
      isLoading={isLoading}
      data={data}
      regions={regions?.data}
    />
  )
}

export default VisitAreaStatisticsContainer
