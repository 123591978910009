import React, { useEffect, useState } from "react";
import { Container, PrimaryDataTable, TableColumn, TableRow } from "@component";
import { Aggregations, Bucket, Code, SearchResponse } from "@type";

interface Props {
  data?: SearchResponse
  dataCount?: SearchResponse
  types?: Array<Code>
  isLoading: boolean
}

const PersonalizationStatisticsPresenter = ({ data, dataCount, types, isLoading }: Props) => {
  const [ items, setItems ] = useState<Array<any>>([])

  useEffect(() => {
    if (data) {
      const array: any[] = []
      getAggregationsBuckets(data.aggregations).forEach((pageBucket: Bucket) =>
        getAggregationsBuckets(pageBucket.aggregations).forEach((typeNumberBucket: Bucket) =>
          getAggregationsBuckets(typeNumberBucket.aggregations).forEach((testTypeBucket: Bucket) => {
            const traceTypeBuckets = getAggregationsBuckets(testTypeBucket.aggregations)
            const consumeBucket = traceTypeBuckets.filter((bucket: Bucket) => bucket.keyAsString === 'CONSUME')
            const eventBucket = traceTypeBuckets.filter((bucket: Bucket) => bucket.keyAsString === 'EVENT')
            const consume = consumeBucket && consumeBucket.length !== 0 ? consumeBucket[0].docCount : 0
            const event = eventBucket && eventBucket.length !== 0 ? eventBucket[0].docCount : 0
            const percentage = consume / event * 100
            array.push({
              rowIndex: array.length + 1,
              page: pageBucket.keyAsString,
              typeNumber: typeNumberBucket.keyAsString,
              testType: testTypeBucket.keyAsString,
              consume: consume === 0 ? '-' : `${consume}건`,
              event: event === 0 ? '-' : `${event}건`,
              percentage: percentage
            })
          }))
        )
      console.log('array', array)
      setItems(() => array)
    }
  }, [ data ])

  const getAggregationsBuckets = (aggregations: any) => {
    const aggregationsAsMap = aggregations.asMap as Aggregations
    return aggregationsAsMap[0].buckets.sort(sortAscending)
  }

  const sortAscending = (a: Bucket, b: Bucket) => a.keyAsString > b.keyAsString ? 1 : -1
  return (
    <Container>
      <PrimaryDataTable
        title={"AI 콕콕 메인 통계"}
        pagination={false}
        headers={["서비스 영역", "세부 영역", "제공 데이터", "클릭(소비) 수", "노출 이벤트 수", "소비 비율"]}
        useKeyword={false}
        dateType={'datetime-local'}
        isLoading={isLoading}>
        {
          items && items.length !== 0 ? (() => {
            return items.map((item, index) => {
              const { rowIndex, page, typeNumber, testType, consume, event, percentage } = item
              const equalsTypeNumberLength = items.filter(item => item.typeNumber === typeNumber).length
              return (
                <TableRow
                  key={Math.random()}
                  enableDetailButton={true}
                  enableOverlay={false}
                  onClickDetailButton={() => {}}>
                  {
                    rowIndex === 1 ? <TableColumn align={"center"} rowSpan={14}>{ page }</TableColumn> : <></>
                  }
                  {
                    index === 0 || items[index - 1].typeNumber !== typeNumber ? <TableColumn align={"center"} rowSpan={equalsTypeNumberLength}>{ typeNumber }</TableColumn> : <></>
                  }
                  <TableColumn align={"center"}>{ testType }</TableColumn>
                  <TableColumn align={"center"}>{ consume }</TableColumn>
                  <TableColumn align={"center"}>{ event }</TableColumn>
                  {
                    percentage === 0 ? (
                      <TableColumn align={"center"}>{ '-' }</TableColumn>
                    ) : (
                      <TableColumn align={"center"} percentage={percentage}>{ percentage === 0 ? '-' : `${percentage.toFixed(1)}%` }</TableColumn>
                    )
                  }
                </TableRow>
              )
            })
          })() : <></>
        }
      </PrimaryDataTable>
    </Container>
  )
}

export default PersonalizationStatisticsPresenter
