import React from "react";
import {UseQueryResult} from "react-query/types/react/types";
import {Code, SearchResponse} from "@type";
import * as QueryBuilder from "@util";
import {useTableContext} from "@hook";
import useFetchElasticsearchQueryProxy from "../../hook/query/elasticsearch/useFetchElasticsearchQueryProxy";
import {PersonalizationStatisticsPresenter} from "@presenter";
import {useTypeCodeQuery} from "@hook/query";

const PersonalizationStatisticsContainer = () => {
  const DEFAULT_PAGING_SIZE = 0
  const {
    keywordState: [ keyword ],
    fromDateTimeState: [ fromDateTime ],
    toDateTimeState: [ toDateTime ],
    searchTypesState: [ searchTypes ],
  } = useTableContext()

  const types: UseQueryResult<Array<Code>> = useTypeCodeQuery(3)

  const createQuery = (aggs: any) => {
    return QueryBuilder.createQuery({
      size: DEFAULT_PAGING_SIZE,
      gte: fromDateTime,
      lte: toDateTime,
      aggs: aggs,
      filter: (() => {
        return [
          {
            "terms": {
              "page.keyword": ["cr_main"]
            }
          },
          {
            "terms": {
              "traceType.keyword": ["EVENT", "CONSUME"]
            }
          },
          {
            "terms": {
              "device.keyword": ["PC", "MOBILE"]
            }
          },
          {
            "terms": {
              "testType.keyword": ["A", "B"]
            }
          },
          {
            "regexp": {
              "eventId.keyword": "[a-zA-Z0-9]{12}"
            }
          },
          {
            "terms": {
              "typeNumber": [1, 2, 3, 4, 5, 6, 7]
            }
          }
        ]
      })()
    })
  }

  const { data, isLoading } : UseQueryResult<SearchResponse> = useFetchElasticsearchQueryProxy("trace-ai*", createQuery({
    0: {
      "terms": {
        "field": "page.keyword",
        "order": {
          "_count": "desc"
        },
        "size": 5
      },
      "aggs": {
        0: {
          "terms": {
            "field": "typeNumber",
            "order": {
              "_count": "asc"
            },
            "size": 20
          },
          "aggs": {
            0: {
              "terms": {
                "field": "testType.keyword",
                "order": {
                  "_count": "desc"
                },
                "size": 5
              },
              "aggs": {
                0: {
                  "terms": {
                    "field": "traceType.keyword",
                    "order": {
                      "_count": "desc"
                    },
                    "size": 5
                  }
                }
              }
            }
          }
        }
      }
    }
  }), false)

  return (
    <PersonalizationStatisticsPresenter
      isLoading={isLoading}
      data={data}
      types={types.data}
    />
  )
}

export default PersonalizationStatisticsContainer
