import React from "react";
import { Container, Table, TableColumn, TableRow } from "@component";
import moment from "moment";
import { getWeekdayText } from "@enum";

interface Props {
  isLoading: boolean
  data?: Array<any>
}

const AreaRoutePresenter = ({ data, isLoading }: Props) => {
  const convertPointString = ({ region, regionCode, sigungu, sigunguCode }: any) => {
    const isRegion = sigunguCode === "0"
    if (isRegion) {
      return region
    } else {
      return `${region} ${sigungu}`
    }
  }

  return (
    <Container classNames={["actionTrackDashboard"]}>
      <Table
        isLoading={isLoading}
        enableKeyword={false}
        enableSearch={false}
        // title={"지역간 자동차 경로 정보 테이블"}
        headers={["출발지", "도착지", "요일", "시간대", "소요시간", "이동거리", "교통요금"]}>
        {
          data ? data.map((item: any, index) => {
            const { origin, destination, dayOfWeek, modifiedAt, seconds, distance, fare } = item
            const hour = moment(modifiedAt).hour()
            const minutes = seconds / 60
            const km = distance / 1000
            return (
              <TableRow
                key={index}
                enableDetailButton={true}
                enableOverlay={false}
                onClickDetailButton={() => {}}>
                <TableColumn align={"center"}>{ convertPointString(origin) }</TableColumn>
                <TableColumn align={"center"}>{ convertPointString(destination) }</TableColumn>
                <TableColumn align={"center"}>{ getWeekdayText(dayOfWeek) } </TableColumn>
                <TableColumn align={"center"}>{ `${hour}시` }</TableColumn>
                <TableColumn align={"center"}>{ `${minutes.toFixed()}분` }</TableColumn>
                <TableColumn align={"center"}>{ `${km.toFixed(1)}km` }</TableColumn>
                <TableColumn align={"center"}>{ `${fare.toLocaleString()}원` }</TableColumn>
              </TableRow>
            )
          }) : <></>
        }
      </Table>
    </Container>
  )
}

export default AreaRoutePresenter
