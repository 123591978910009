import { createContext, Dispatch, SetStateAction, useContext } from "react";
import moment, { Moment } from "moment";

// export interface ArrayState<T> {
//   value: T
//   push?: (item: T) => void
//   remove?: (index: number) => void
//   set: Dispatch<SetStateAction<T>>
// }

// export interface State<T> {
//   value: T
//   set: Dispatch<SetStateAction<T>>
// }

type State<T> = [ T, Dispatch<SetStateAction<T>> ]
type ArrayState<T> = [ Array<T>, (item: T) => void, (index: number) => void, Dispatch<SetStateAction<Array<T>>> ]

export interface Context {
  keywordState: State<string>
  fromDateTimeState: State<Moment>
  toDateTimeState: State<Moment>
  pageState: State<number>
  filtersState: ArrayState<any>
  termsState: ArrayState<any>
  searchTypesState: State<{
    [key: string]: string
  }>
}

export const TableContext = createContext<Context>({
  keywordState: ['', () => {}],
  fromDateTimeState: [moment(), () => {}],
  toDateTimeState: [moment(), () => {}],
  pageState: [0, () => {}],
  filtersState: [[], () => {}, () => {}, () => {}],
  termsState: [[], () => {}, () => {}, () => {}],
  searchTypesState: [{}, () => {}]
})

export const useTableContext = () => {
  return useContext(TableContext)
}

