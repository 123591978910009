import { UseQueryResult } from "react-query/types/react/types";
import { SearchResponse } from "@type";
import { useQuery } from "react-query";
import { fetchElasticsearchQueryProxy } from "@api";

const useFetchElasticsearchQueryProxy = (indices: string, query: any, useTotalHits?: boolean): UseQueryResult<SearchResponse> => {
  return useQuery({
    retry: false,
    queryKey: [ 'fetchElasticsearchQueryProxy', { ...query }],
    queryFn: fetchElasticsearchQueryProxy(query, indices, useTotalHits)
  })
}

export default useFetchElasticsearchQueryProxy
