export enum ActionType {
  VIEW = "콘텐츠 상세 소비",
  VIEW_LOCATION = "콘텐츠 상세 소비 (여행지도)",
  LIKE = "좋아요",
  BOOKMARK = "즐겨찾기",
  SHARE = "공유하기",
  UPLOAD_PHOTO = "사용자 사진 등록",
  REQUEST_EDIT_CONTENT = "관광정보 수정요청",
  PRINT = "인쇄하기",
  COMMENT = "사용자 댓글 작성",
  COMMENT_LIKE = "사용자 댓글 좋아요",
  COMMENT_REPLY = "사용자 댓글 답글 작성",
  TAG = "태그 클릭",
  AREA = "지역 클릭",
  SEARCH_KEYWORD = "내/외부 검색 키워드",
  STAMP = "발도장",
  STAMP_STARS = "발도장 만족도 별점",
  NAVI = "네비게이션 실행",
  TRSS = "여행구독 가볼래/안가볼래",
  FEEDBACK = "좋아요/별로에요",
  ABC_COURSE = "ABC 상점",
  PROMOTION = "여행상품 홍보관",
  UNDEFINED = "정의되지 않음"
}

export type Type = {
  [key in ActionType]?: () => void
}

export const getFieldByActionType = ({ actionType, actionInfo, tags, regions }: {
  actionType: ActionType,
  actionInfo: any,
  tags?: Array<any>
  regions: Array<any>
}) => {
  const set = {
    [ActionType.VIEW]: () => '-',
    [ActionType.VIEW_LOCATION]: () => '-',
    [ActionType.LIKE]: () => '-',
    [ActionType.BOOKMARK]: () => '-',
    [ActionType.SHARE]: () => '-',
    [ActionType.UPLOAD_PHOTO]: () => getAreaByActionInfo({ actionInfo, regions }),
    [ActionType.REQUEST_EDIT_CONTENT]: () => '-',
    [ActionType.PRINT]: () => '-',
    [ActionType.COMMENT]: () => actionInfo.condition === 'POSITIVE' ? '긍정' : '부정',
    [ActionType.COMMENT_LIKE]: () => '-',
    [ActionType.COMMENT_REPLY]: () => actionInfo.condition === 'POSITIVE' ? '긍정' : '부정',
    [ActionType.TAG]: () => tags?.filter(tag => tag.id === actionInfo.tagid).map((tag => tag.tag)),
    [ActionType.AREA]: () => getAreaByActionInfo({ actionInfo, regions }),
    [ActionType.SEARCH_KEYWORD]: () => `${actionInfo.searchtype}, ${actionInfo.keyword.length > 30 ? `${actionInfo.keyword.substring(0, 30)} ...` : actionInfo.keyword}`,
    [ActionType.STAMP]: () => getAreaByActionInfo({ actionInfo, regions }),
    [ActionType.NAVI]: () => `${actionInfo.kind}, ${getAreaByActionInfo({ actionInfo, regions })}`,
    [ActionType.TRSS]: () => actionInfo.condition === 'POSITIVE' ? '가볼래' : '안가볼래',
    [ActionType.STAMP_STARS]: () => actionInfo.score,
    [ActionType.FEEDBACK]: () => actionInfo.condition === 'POSITIVE' ? '좋아요' : '별로에요',
    [ActionType.ABC_COURSE]: () => getAreaByActionInfo({ actionInfo, regions }),
    [ActionType.PROMOTION]: () => getAreaListByActionInfo({ actionInfo, regions }),
  } as any
  return set[actionType] ? set[actionType]() : '-'
}

export const getAreaListByActionInfo = ({ actionInfo, regions }: { actionInfo: any, regions: Array<any> }) => {
  const { areacode } = actionInfo
  if (areacode) {
    if (areacode instanceof Array) {
      return areacode.map((code: any) => {
        return regions[code]?.name
      }).join(", ")
    } else {
      return regions[areacode]?.name
    }
  }
  return "전체"
}

export const getAreaByActionInfo = ({ actionInfo, regions }: { actionInfo: any, regions: Array<any> }) => {
  const { areacode, sigungucode } = actionInfo
  const area = regions[areacode]
  if (!sigungucode) {
    return area?.name
  }
  if (!areacode || !sigungucode) {
    return sigungucode === '0' || sigungucode === 'All' ? '전체' : sigungucode
  }
  try {
    if (sigungucode instanceof Array) {
      return `${sigungucode.map(code => {
        if (code === '0' || code === 'All') {
          return `${area?.name} 전체`
        }
        if (area && area[code]) {
          return `${area?.name} ${regions[ areacode ][code]?.name}`
        } else {
          return code
        }
      }).join(", ")}`
    } else {
      return `${regions[areacode].name} ${regions[areacode][sigungucode].name}`
    }
  } catch (e) {
    return sigungucode === '0' || sigungucode === 'All' ? '전체' : sigungucode
  }
}

export const getActionType = (key: string): ActionType => {
  const actionType = Object.entries(ActionType)
    .filter(entry => entry[0] === key)
  return actionType.length !== 0 ? actionType[0][1] : ActionType.UNDEFINED
}

export const getStringByValue = (value: string): string => {
  console.log('Object.entries(ActionType)', Object.entries(ActionType.UNDEFINED[0]))
  const actionType = Object.entries(ActionType)
    .filter(entry => entry[1] === value)
  return actionType.length !== 0 ? actionType[0][0] : ActionType.UNDEFINED
}
