import React from "react";
import { Container, PrimaryDataTable, TableColumn, TableRow } from "@component";
import { Bucket, SearchResponse } from "@type";
import { useTableContext } from "@hook";

interface Props {
  data?: SearchResponse
  isLoading: boolean
}

const KeywordStatisticsPresenter = ({ data, isLoading }: Props) => {
  const { searchTypesState: [ searchTypes ] } = useTableContext()
  return (
    <Container>
      <PrimaryDataTable
        title={"검색어 성향"}
        pagination={false}
        headers={["검색어", "누적횟수", "비율"]}
        useKeyword={true}
        selectItems={[
          {
            label: '내부/외부',
            key: 'type',
            items: [
              {
                label: '내부 검색',
                value: 'SEARCH'
              },
              {
                label: '외부 유입 검색',
                value: 'INFLOW'
              }
            ]
          },
          {
            label: '구분',
            key: 'category',
            hidden: searchTypes.type !== 'SEARCH',
            items: [
              {
                label: '많이 찾는 검색어',
                value: 'POPULAR'
              },
              {
                label: '키워드별 선호 콘텐츠',
                value: 'KEYWORD'
              }
            ]
          }
        ]}
        isLoading={isLoading}>
        {
          data ? data.aggregations.asMap[0].buckets
            .sort((a, b) => a.docCount < b.docCount ? 1 : -1)
            .map((bucket: Bucket, index) => {
            const { key, docCount, aggregations } = bucket;
            const percentage = docCount / data.hits.totalHits.value * 100
            return (
              <TableRow
                key={index}
                enableDetailButton={true}
                enableOverlay={false}
                onClickDetailButton={() => {}}>
                <TableColumn align={"center"}>{ key }</TableColumn>
                <TableColumn align={"center"}>{ docCount.toLocaleString() }건</TableColumn>
                <TableColumn align={"center"} percentage={percentage}>{ percentage.toFixed(1) }%</TableColumn>
              </TableRow>
            )
          }) : <></>
        }
      </PrimaryDataTable>
    </Container>
  )
}

export default KeywordStatisticsPresenter
