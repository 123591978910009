import React, { ReactNode } from "react";
import { Button } from "@component";
import { ColorType } from "@enum/ColorType";
import ClipLoader from "react-spinners/ClipLoader";
import { useTableContext } from "@hook";

interface Props {
  headers: string[]
  totalCount?: number
  isLoading?: boolean
  lastPage?: number
  xScroll?: boolean
  yScroll?: boolean
  enableKeyword?: boolean
  enableSearch?: boolean
  enableDateTime?: boolean
  pagination?: boolean
  children: ReactNode
}

enum PagingType {
  FIRST = "first",
  PREVIOUS = "previous",
  NEXT = "next",
  LAST = "last"
}

const Table = (
  {
    headers,
    children,
    totalCount,
    lastPage = 0,
    isLoading,
    pagination = true,
    xScroll = true,
    yScroll = true,
  }: Props) => {
  const { pageState: [ page, setPage ] } = useTableContext()
  const handlePaging = (type: PagingType) => {
    const nextPage: number = (() => {
      switch (type) {
        case PagingType.FIRST:
          return 0
        case PagingType.PREVIOUS:
          return page - 1
        case PagingType.NEXT:
          return page + 1
        case PagingType.LAST:
          return lastPage - 1
      }
      return page
    })()
    setPage(nextPage)
  }

  return (
    <div className={"tableWrapper"}>
      <div className={"tableScrollable"} style={{
        overflowX: xScroll ? "auto" : "hidden",
        overflowY: yScroll ? "auto" : "hidden",
      }}>
      {
        isLoading ? (
          <div className={"tableLoader"}>
            <ClipLoader loading={true} size={50} color={"blue"} />
          </div>
        ) : (
          <table className={"table"}>
            <thead className={"tableHeaderRow"}>
              <tr>
                <th className={"tableRowHeaderAdditional"} />
                {
                  headers.map((header, index) => (
                    <th className={"tableHeaderColumn"} key={index} dangerouslySetInnerHTML={{ __html: header }} />
                  ))
                }
              </tr>
            </thead>
            <tbody>
            { children }
            </tbody>
          </table>
        )
      }
      </div>
      {
        pagination && (
          <div className={"tableBottomRow"}>
            {
              totalCount ? (
                <div className={"totalCount"}>
                  <label>전체 </label>
                  <strong>{totalCount.toLocaleString()}건</strong>
                </div>
              ) : <></>
            }
            <div className={"pageCount"}>
              <strong>{(page + 1).toLocaleString()}{lastPage !== 0 && ` / ${lastPage?.toLocaleString()}`}</strong>
              <label> 페이지</label>
            </div>
            <div style={{
              alignItems: 'center',
              display: 'flex'
            }}>
              <Button
                icon={"first_page"}
                color={ColorType.WHITE}
                onClick={() => handlePaging(PagingType.FIRST)}
                enable={page !== 0}
              />
              <Button
                icon={"keyboard_arrow_left"}
                color={ColorType.WHITE}
                onClick={() => handlePaging(PagingType.PREVIOUS)}
                enable={page !== 0}
              />
              <Button
                icon={"keyboard_arrow_right"}
                color={ColorType.WHITE}
                onClick={() => handlePaging(PagingType.NEXT)}
                enable={page !== (lastPage - 1)}
              />
              <Button
                icon={"last_page"}
                color={ColorType.WHITE}
                onClick={() => handlePaging(PagingType.LAST)}
                show={lastPage !== 0}
                enable={page !== (lastPage - 1)}
              />
            </div>
          </div>
        )
      }
    </div>
  )
}

export default Table
