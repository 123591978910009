import frontApiClient from "@lib/FrontApiClient";
import { ApiResponse } from "@type";

export const fetchSearchContent = (title: string) => async () => {
  const request = await frontApiClient.get(`/v1/search/content`, {
    params: {
      title,
      isExact: false
    }
  })
  const response: ApiResponse<Array<any>> = request.data
  return response.data
}
