import { useQuery, UseQueryResult } from "react-query";
import { fetchCodeList } from "@api";
import { Code } from "@type";

const useTypeCodeQuery = (type: number): UseQueryResult<Array<Code>> => {
  return useQuery({
    retry: false,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    refetchOnReconnect: false,
    queryKey: [ 'fetchCodeList', { type }],
    queryFn: fetchCodeList(type)
  })
}

export default useTypeCodeQuery
