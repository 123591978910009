import React from "react";
import { useUserActivityTendencyContext } from "@page";
import { UserTendencyAreaModal, UserTendencyKeywordModal, UserTendencyVisitModal } from "@presenter/tendency/modal";
import { TendencyType } from "@enum";
import { isEmpty } from "lodash";
import { Code } from "@type";
import UserTendencyCategoryModal from "@presenter/tendency/modal/UserTendencyCategoryModal";
import UserTendencyContentModal from "@presenter/tendency/modal/UserTendencyContentModal";
import UserTendencyTagModal from "@presenter/tendency/modal/UserTendencyTagModal";

export interface ActivityDetailModalProps {
  data?: any
  isLoading: boolean
  regions?: any
  types?: Array<Code> | undefined
}

const UserTendencyDetailModalPresenter = ({ data, isLoading, regions, types }: ActivityDetailModalProps) => {
  const { detailAttribute: { type } } = useUserActivityTendencyContext()

  if (!isEmpty(data)) {
    switch (type) {
      case TendencyType.KEYWORD:
        return <UserTendencyKeywordModal data={data} isLoading={isLoading}/>
      case TendencyType.AREA:
        return <UserTendencyAreaModal data={data} isLoading={isLoading} regions={regions} />
      case TendencyType.VISIT:
        return <UserTendencyVisitModal data={data} isLoading={isLoading} regions={regions} />
      case TendencyType.CATEGORY:
        return <UserTendencyCategoryModal data={data} isLoading={isLoading} types={types} />
      case TendencyType.CONTENT:
        return <UserTendencyContentModal data={data} isLoading={isLoading} />
      case TendencyType.TAG:
        return <UserTendencyTagModal data={data} isLoading={isLoading} />
      case TendencyType.SEARCH_CONTENT:
      default:
        return <></>
    }
  }
  return <></>
}

export default UserTendencyDetailModalPresenter
