import { useQuery, UseQueryResult } from "react-query";
import { fetchSearchContent } from "@api";

const useSearchContentQuery = (title: string, enable?: boolean): UseQueryResult<Array<any>> => {
  return useQuery({
    retry: false,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    refetchOnReconnect: false,
    enabled: enable,
    queryKey: [ 'fetchSearchContent', { title }],
    queryFn: fetchSearchContent(title)
  })
}

export default useSearchContentQuery
