import { Code } from "@type";

export const getContentType = (contentType: number, codeList?: Array<Code>) => {
  const type = getContentTypeAsString(contentType)
  if (type) {
    return type
  }
  return getContentTypeFromCodeList(contentType, codeList)
}

export const getContentTypeFromCodeList = (contentType: number, codeList?: Array<Code>) => {
  const array = codeList?.filter(code => code.big === `${contentType}`)
    .map(code => code.value)
  if (array && array.length > 0) {
    return array[0]
  }
  return `${contentType}`
}

const getContentTypeAsString = (contentType: number) => {
  switch (contentType) {
    case 12:
      return "관광지"
    case 14:
      return "문화시설"
    case 15:
      return "축제행사공연"
    case 25:
      return "여행코스"
    case 28:
      return "레포츠"
    case 32:
      return "숙박"
    case 38:
      return "쇼핑"
    case 39:
      return "음식점"
    default:
      return undefined
  }
}
