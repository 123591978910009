export enum TendencyType {
  KEYWORD = "KEYWORD",
  AREA = "AREA",
  CATEGORY = "CATEGORY",
  CONTENT = "CONTENT",
  TAG = "TAG",
  SEARCH_CONTENT = "SEARCH_CONTENT",
  VISIT = "VISIT",
  COURSE_AREA = "COURSE_AREA",
  UNDEFINED = "UNDEFINED"
}
