import { Moment } from "moment";

interface Props {
  page?: number
  size?: number
  gte?: Moment
  lte?: Moment
  useRangeTime?: boolean
  aggs?: any
  timeFieldName?: string
  collapse?: any
  should?: Array<any>
  mustNot?: Array<any>
  filter?: Array<any>
  docvalue_fields?: Array<any>
}

const createQuery = (
  {
    page = 0,
    size = 500,
    gte,
    lte,
    useRangeTime = true,
    should = [],
    mustNot = [],
    aggs,
    timeFieldName = '@timestamp',
    collapse,
    filter = [],
    docvalue_fields,
  }: Props) => {
  let query = {}
  let range = {}
  if (page !== -1) {
    query = {
      from: page * size,
    }
  }
  if (aggs) {
    query = {
      ...query,
      aggs: aggs
    }
  }
  if (collapse) {
    query = {
      ...query,
      collapse
    }
  }
  if (gte && lte) {
    range = {
      "range": {
        [timeFieldName]: {
          "gte": useRangeTime ? gte.toISOString() : gte.format("yy-MM-DD"),
          "lte": useRangeTime ? lte.toISOString() : lte.format("yy-MM-DD"),
          "format": "strict_date_optional_time"
        }
      }
    }
    filter.push(range)
  }
  return {
    ...query,
    "version": true,
    "size": size,
    "sort": [
      {
        "@timestamp": {
          "order": "desc",
          "unmapped_type": "boolean"
        }
      }
    ],
    "docvalue_fields": docvalue_fields,
    "query": {
      "bool": {
        "must_not": mustNot,
        "filter": [
          {
            "bool": {
              "should": should,
            }
          },
          ...filter
        ]
      },
    }
  }
}

export { createQuery }
