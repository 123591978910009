import frontApiClient from "@lib/FrontApiClient";
import { ApiResponse } from "@type";

export const fetchUser = (keyword: string) => async () => {
  const request = await frontApiClient.get('/v1/user/search', {
    params: {
      keyword,
      limit: 50
    }
  })
  const response: ApiResponse<Array<any>> = request.data
  return response.data
}

export const fetchUserTendencyAnalyze = (ga: string, snsId?: string) => async () => {
  const request = await frontApiClient.get(`/v1/user/tendency/analyze`, {
    headers: {
      'X-SNS-ID': snsId || ''
    },
    params: {
      ga
    }
  })
  const response: ApiResponse<any> = request.data
  return response.data
}
