import frontApiClient from "@lib/FrontApiClient";
import { ApiResponse, SearchResponse } from "@type";

export const fetchElasticsearchQuery = (query: any) => async () => {
  const request = await frontApiClient.post('/v1/elasticsearch/query', query, {
    params: {
      indices: 'action-track*'
    }
  })
  const response: ApiResponse<Array<any>> = request.data
  return response.data
}

export const fetchElasticsearchQueryProxy = (query: any, indices: string, useTotalHits: boolean = false) => async (): Promise<SearchResponse> => {
  const request = await frontApiClient.post('/v1/elasticsearch/query/proxy', query, {
    params: {
      indices: indices,
      useTotalHits: useTotalHits
    }
  })
  const response: ApiResponse<SearchResponse> = request.data
  return response.data
}
