import React, { useState } from "react";
import { UseQueryResult } from "react-query/types/react/types";
import { SearchResponse } from "@type";
import * as QueryBuilder from "@util";
import { useTableContext } from "@hook";
import useFetchElasticsearchQueryProxy from "../../hook/query/elasticsearch/useFetchElasticsearchQueryProxy";
import { ContentStatisticsPresenter } from "@presenter";
import useSearchContentQuery from "../../hook/query/content/useSearchContentQuery";

const ContentStatisticsContainer = () => {
  const DEFAULT_PAGING_SIZE = 0
  const {
    keywordState: [ keyword ],
    fromDateTimeState: [ fromDateTime ],
    toDateTimeState: [ toDateTime ],
    searchTypesState: [ searchTypes ],
  } = useTableContext()
  const [ cacheContents, setCacheContents ] = useState<{ [key: string]: string }>({})

  const contents = useSearchContentQuery(keyword, keyword !== undefined && keyword !== '')
  // const allContents = useSearchContentQuery('', true)
  //
  // useEffect(() => {
  //   if (allContents.data) {
  //     const contents: { [key: string]: string } = {}
  //     allContents.data.forEach(content => {
  //       contents[content.cotId] = content.title
  //     })
  //     setCacheContents(contents)
  //   }
  // }, [ allContents ])

  const createQuery = (aggs: any) => {
    return QueryBuilder.createQuery({
      size: DEFAULT_PAGING_SIZE,
      gte: fromDateTime,
      lte: toDateTime,
      timeFieldName: 'date',
      aggs: aggs,
      filter: (() => {
        let array: any = [
          {
            "match_phrase": {
              "type": "CONTENT"
            }
          },
          {
            "match_phrase": {
              "key.condition": searchTypes.condition
            }
          }
        ]
        if (searchTypes.type === 'COT_ID' && keyword !== '') {
          array = [
            ...array,
            {
              "regexp": {
                "key.value.keyword": `.*${keyword}.*`
              }
            }
          ]
        } else if (searchTypes.type === 'TITLE' && keyword !== '') {
          const cotIds = contents.data?.map(content => content.cotId)
          if (cotIds && cotIds.length !== 0) {
            array = [
              ...array,
              {
                "terms": {
                  "key.value.keyword": cotIds
                }
              }
            ]
          }
        }
        return array
      })()
    })
  }

  const { data: dataCount } : UseQueryResult<SearchResponse> = useFetchElasticsearchQueryProxy("user-tendency-statistics", createQuery({
    0: {
      "sum": {
        "field": "count"
      }
    }
  }), false)

  const { data, isLoading }: UseQueryResult<SearchResponse> = useFetchElasticsearchQueryProxy("user-tendency-statistics", createQuery({
    0: {
      "terms": {
        "field": "key.value.keyword",
        "order": {
          "1": "desc"
        },
        "size": 20
      },
      aggs: {
        "1": {
          "sum": {
            "field": "count"
          }
        }
      }
    }
  }), false)

  return (
    <ContentStatisticsPresenter
      isLoading={isLoading}
      data={data}
      dataCount={dataCount}
      cacheContents={cacheContents}
    />
  )
}

export default ContentStatisticsContainer
