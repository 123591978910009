import React from "react";
import { TableContext, useTable } from "@hook";
import { PageTemplate } from "@component";
import { PersonalizationStatisticsContainer } from "@container";

const PersonalizationStatistics = () => {
  const tableState = useTable({})
  return (
    <TableContext.Provider value={tableState}>
      <PageTemplate>
        <PersonalizationStatisticsContainer />
      </PageTemplate>
    </TableContext.Provider>
  )
}

export default PersonalizationStatistics
