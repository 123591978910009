import { useState } from "react";
import moment, { Moment } from "moment";
import { Filter } from "@component";
import { useArrayState } from "@hook/state/index";

interface Props {
  initial?: {
    filters?: Array<Filter>
    fromDateTime?: Moment
    toDateTime?: Moment
    searchTypes?: {
      [key: string]: string
    }
  }
}

const useTable = ({ initial }: Props) => {
  const defaultGte = moment().subtract(30, 'm')
  const defaultLte = moment()
  return {
    keywordState: useState<string>(''),
    fromDateTimeState: useState<Moment>(initial?.fromDateTime || defaultGte),
    toDateTimeState: useState<Moment>(initial?.toDateTime || defaultLte),
    pageState: useState<number>(0),
    termsState: useArrayState<{ name: string, value: string }>([]),
    filtersState: useArrayState<Filter | undefined>(initial?.filters),
    searchTypesState: useState<{ [key: string]: string }>(initial?.searchTypes || {})
  }
}

export default useTable
