import React, { useEffect, useState } from "react";
import { UseQueryResult } from "react-query/types/react/types";
import { useQuery } from "react-query";
import { fetchMatrix } from "@api";
import { useTableContext } from "@hook"
import { AreaRoutePresenter } from "@presenter";
import { Filter, FilterItem } from "@component";

const AreaRouteMatrixContainer = () => {
  const DEFAULT_PAGING_SIZE = 200
  const {
    pageState: [ page ],
    filtersState: [ filters ]
  } = useTableContext()

  const [ query, setQuery ] = useState({
    page: page,
    offset: DEFAULT_PAGING_SIZE
  })

  const { data, isLoading }: UseQueryResult<Array<any>> = useQuery({
    retry: false,
    queryKey: [ 'matrix-area-route-search', { query }],
    queryFn: fetchMatrix("area-route", { query })
  })

  useEffect(() => {
    setQuery(query => {
      let filterList: any = {}
      filters
        .map(((filter: Filter) => ({
          [filter.key]: filter.items.filter((item: FilterItem) => item.checked).map(item => item.value).join(",")
        })))
        .forEach((filter: any) => {
          filterList = { ...filterList, ...filter }
        })
      return {
        ...query,
        ...filterList
      }
    })
  }, [ page, filters ])

  return (
    <AreaRoutePresenter
      isLoading={isLoading}
      data={data}
    />
  )
}

export default AreaRouteMatrixContainer
