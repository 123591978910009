import React from "react";
import { TableContext, useTable } from "@hook";
import { PageTemplate } from "@component";
import { AppMainRealmStatisticsContainer, AppMainUserStatisticsContainer } from "@container";
import { useParams } from "react-router";

const AppMainRealmStatistics = () => {
  const tableState = useTable({})
  const params = useParams()
  return (
    <TableContext.Provider value={tableState}>
      <PageTemplate>
        {(() => {
          if (params?.type === 'user') {
            return <AppMainUserStatisticsContainer />
          } else {
            return <AppMainRealmStatisticsContainer />
          }
        })()}
      </PageTemplate>
    </TableContext.Provider>
  )
}

export default AppMainRealmStatistics
