interface Code {
  [key: number]: string
}

const regionCode: Code = {
  0: "전국",
  1: "서울",
  2: "인천",
  3: "대전",
  4: "대구",
  5: "광주",
  6: "부산",
  7: "울산",
  8: "세종시",
  31: "경기도",
  32: "강원도",
  33: "충청북도",
  34: "충청남도",
  35: "경상북도",
  36: "경상남도",
  37: "전라북도",
  38: "전라남도",
  39: "제주도",
}

const getRegionList = (ignoreAll: boolean = true) => {
  return Object.entries(regionCode)
    .filter(entry => ignoreAll ? entry[0] !== '0' : true).map(entry => ({
    text: entry[1],
    value: entry[0]
  }))
}

const getRegionName = (code: number) => {
  return regionCode[code]
}

export default regionCode
export { getRegionName, getRegionList, regionCode }
