import React from "react";
import { UseQueryResult } from "react-query/types/react/types";
import { SearchResponse } from "@type";
import * as QueryBuilder from "@util";
import { useTableContext } from "@hook";
import useFetchElasticsearchQueryProxy from "../../hook/query/elasticsearch/useFetchElasticsearchQueryProxy";
import { TagStatisticsPresenter } from "@presenter";

const TagStatisticsContainer = () => {
  const DEFAULT_PAGING_SIZE = 0
  const {
    keywordState: [ keyword ],
    fromDateTimeState: [ fromDateTime ],
    toDateTimeState: [ toDateTime ],
    searchTypesState: [ searchTypes ],
  } = useTableContext()

  const createQuery = (aggs: any) => {
    return QueryBuilder.createQuery({
      size: DEFAULT_PAGING_SIZE,
      gte: fromDateTime,
      lte: toDateTime,
      timeFieldName: 'date',
      aggs: aggs,
      filter: (() => {
        let array: any = [
          {
            "match_phrase": {
              "type": "TAG"
            }
          },
          {
            "match_phrase": {
              "key.condition": searchTypes.condition
            }
          }
        ]
        if (keyword !== '') {
          array = [
            ...array,
            {
              "regexp": {
                "key.value": `.*${keyword}.*`
              }
            }
          ]
        }
        return array
      })()
    })
  }

  const { data: dataCount } : UseQueryResult<SearchResponse> = useFetchElasticsearchQueryProxy("user-tendency-statistics", createQuery({
    0: {
      "sum": {
        "field": "count"
      }
    }
  }), false)

  const { data, isLoading }: UseQueryResult<SearchResponse> = useFetchElasticsearchQueryProxy("user-tendency-statistics", createQuery({
    0: {
      "terms": {
        "field": "key.value.keyword",
        "order": {
          "1": "desc"
        },
        "size": 20
      },
      aggs: {
        "1": {
          "sum": {
            "field": "count"
          }
        }
      }
    }
  }), true)

  return (
    <TagStatisticsPresenter
      isLoading={isLoading}
      data={data}
      dataCount={dataCount}
    />
  )
}

export default TagStatisticsContainer
