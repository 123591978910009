import { Dispatch, SetStateAction, useState } from "react";

const useArrayState = <T> (defaultValue: Array<T> = []) => {
  const [ array, setArray ] = useState(defaultValue)

  const push = (item: T) => {
    setArray(prevState => [
      ...prevState,
      item
    ])
  }

  const remove = (index: number) => {
    setArray(prevState => prevState.filter((item, _index) => _index !== index))
  }

  return [
    array,
    push,
    remove,
    setArray,
  ] as [ Array<T>, (item: T) => void, (index: number) => void, Dispatch<SetStateAction<Array<T>>> ]
}

export default useArrayState
