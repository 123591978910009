import React from "react";
import { TableContext, useTable } from "@hook";
import { PageTemplate } from "@component";
import { AreaRouteMatrixContainer } from "@container";
import { getRegionList } from "@util";

const AreaRouteMatrix = () => {
  const tableState = useTable({
    initial: {
      filters: [
        {
          text: "출발지",
          key: "origin",
          items: [
            ...getRegionList()
          ]
        },
        {
          text: "도착지",
          key: "destination",
          items: [
            ...getRegionList()
          ]
        },
        {
          text: "기준 요일",
          key: "dayOfWeek",
          items: [
            {
              text: "수요일",
              value: "WEDNESDAY"
            },
            {
              text: "토요일",
              value: "SATURDAY"
            }
          ]
        },
        {
          text: "기준 시간대",
          key: "hour",
          items: [
            {
              text: "9시",
              value: 9
            },
            {
              text: "12시",
              value: 12
            },
            {
              text: "18시",
              value: 18
            },
            {
              text: "22시",
              value: 22
            }
          ]
        }
      ]
    }
  })

  return (
    <TableContext.Provider value={tableState}>
      <PageTemplate>
        <AreaRouteMatrixContainer />
      </PageTemplate>
    </TableContext.Provider>
  )
}

export default AreaRouteMatrix
